import { NavigationComponent } from './common/navigation/navigation.component';
import { HomeLayoutComponent } from './modules/home-layout/home-layout.component';
import { LoginLayoutComponent } from './modules/login-layout/login-layout.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './modules/user/login/login.component';
import { AuthGuardService } from './shared/guard/auth-guard.service';


const routes: Routes = [
  {
    path: 'login', component: LoginLayoutComponent, children: [
      { path: '', component: LoginComponent },

    ]
  },
  {
    path: 'main', component: HomeLayoutComponent, canActivate: [AuthGuardService], children: [
      { path: '', redirectTo: 'home', pathMatch: 'full' },
      { path: 'home', loadChildren: () => import('./modules/dashboard/dashboard.module').then(m => m.DashboardModule) },
      { path: 'profile', loadChildren: () => import('./modules/profile/profile.module').then(m => m.ProfileModule) },
      { path: 'history', loadChildren: () => import('./modules/history/history.module').then(m => m.HistoryModule) },
      { path: 'report', loadChildren: () => import('./modules/report/report.module').then(m => m.ReportModule) },
      { path: 'assignment', loadChildren: () => import('./modules/assignment/assignment.module').then(m => m.AssignmentModule) },
    ]
  },

  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: '**', redirectTo: 'login', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
