import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { ConnectionService } from 'ng-connection-service';

@Injectable({
    providedIn: 'root'
})
export class AdminDetailService {
    public sessionData: any;
    public access_token = '';
    // public refresh_token = '';
    public userId: any;
    public header: any;
    public username: any;
    public title: any = "Dashboard";
    currDate = new Date();
    public offset: any = this.offsetValue();
    public userDetails: any;
    public API_URL = "http://65.0.226.231:8080";
    // public API_URL = "http://localhost:8080";
    public API_URL_IOTA = "https://api.iotasmart.com";
    public MQTT_URL = "65.0.226.231";
    // public MQTT_URL = "localhost";
    public CLIENT_ID = "iotasmart";
    public CLIENT_SECRET = "iotasmart-auth";
    public APIKEY = 'IOTASMART'
    public deviceList: any = [];
    public status = 'ONLINE';
    public isConnected = true;
    constructor(private connectionService: ConnectionService) {
        this.initSessionData();
        this.initAdminData();
        this.connectionService.monitor().subscribe(isConnected => {
            this.isConnected = isConnected;
            if (this.isConnected) {
                this.status = "ONLINE";
            }
            else {
                this.status = "OFFLINE";
            }
        })
    }

    initAdminData() {
        if (sessionStorage.getItem('userDetail') !== null) {
            this.userDetails = JSON.parse(sessionStorage.getItem('userDetail') || '');
            this.userId = this.userDetails.user.id;
            this.username = this.userDetails.user.username;
        }
    }

    updateAdminData(session: any) {
        sessionStorage.setItem('userDetail', JSON.stringify(session));
        this.userDetails = session;
        this.userId = this.userDetails.user.id;
        this.username = this.userDetails.user.username;
    }

    initSessionData() {
        if (sessionStorage.getItem('login') !== null) {
            this.sessionData = JSON.parse(sessionStorage.getItem('login') || '');

            this.access_token = this.sessionData.access_token;
            // this.refresh_token = this.sessionData.refresh_token;

            this.username = this.sessionData.username;
            this.header = {
                headers: new HttpHeaders().set('Authorization', `Bearer bd14ad81-76e2-4a66-9a21-b4d4a64f82be`)
            };
        }

    }

    updateSessionData(session: any) {
        sessionStorage.setItem('login', JSON.stringify(session));
        this.sessionData = session;
        this.access_token = this.sessionData.access_token;
        // this.refresh_token = this.sessionData.refresh_token;

        this.header = {
            headers: new HttpHeaders().set('Authorization', `Bearer bd14ad81-76e2-4a66-9a21-b4d4a64f82be`)
        };
    }
    offsetValue() {
        var offst: any = this.currDate.getTimezoneOffset();
        const h = Math.round(offst / 60) * 100;
        const m = ((offst / 60) - Math.round(offst / 60)) * 60;
        const offsetInH = (h + m) * -1;

        if (offsetInH > 0) {
            return offst = '+' + offsetInH;
        }
        else {
            return offst = '' + offsetInH;
        }
    }


}