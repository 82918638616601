import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { DashboardComponent } from './dashboard.component';
import { DashboardRoutingModule } from './dashboard-routing.module';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { DashboardService } from './dashboard.service';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { GoogleChartsModule } from 'angular-google-charts';

@NgModule({
    declarations: [
        DashboardComponent,

    ],
    imports: [
        ReactiveFormsModule,
        HttpClientModule,
        RouterModule,
        CommonModule,
        DashboardRoutingModule,
        MatCardModule,
        MatIconModule,
        NgxChartsModule,
        GoogleChartsModule,

    ],
    providers: [DashboardService],
    bootstrap: []
})
export class DashboardModule { }