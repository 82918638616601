import { AdminDetailService } from './../../shared/admin-detail.service';
import { FormGroup, FormBuilder } from '@angular/forms';
import { ReportService } from './report.service';
import { Component, OnInit } from '@angular/core';
import { ExportAsConfig, ExportAsService } from 'ngx-export-as';
import { MatTableDataSource } from '@angular/material/table';
import { DatePipe } from '@angular/common';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { NoonReportDetailsComponent } from './noon-report-details/noon-report-details.component';

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.css']
})
export class ReportComponent implements OnInit {
  maxDate = new Date();
  constructor(public matDialog: MatDialog,
    private reportService: ReportService,
    private exportAsService: ExportAsService,
    private fb: FormBuilder,
    private adminDetailService: AdminDetailService,
    private datePipe: DatePipe,) {
    this.adminDetailService.title = "Report";

  }
  pdfButtonDisabled = false;
  excelButtonDisabled = false;
  reportTypeList = ['Summary Report', 'Noon Report', 'Emission Report'];
  dateType = 'today';
  reportType = 'Summary Report';
  vesselSelected = "";
  noonVesselSelected = "";
  vesselList: any = [];
  public displayedSummaryColumns: string[] = ['vesselName', 'nauticalDriven', 'cumCO', 'cumSOx', 'cumNOx', 'cumCO2', 'cumO2'];
  public displayedNoonColumns: string[] = ['vname', 'imo', 'cp', 'ce', 'rt', 'rs', 'CO', 'SOx', 'NOx', 'CO2', 'O2', 'dt'];
  public displayedEmissionColumns: string[] = ['dt', 'CO', 'SOx', 'NOx', 'CO2', 'O2', 'speed'];
  public datasource: any;
  summaryList: any = [];
  summaryTable: any = [];
  noonTable: any = [];
  emissionTable: any = [];
  noonData: any;
  emissionData: any;
  custom!: FormGroup;
  noonForm!: FormGroup;
  pageNumber = 0;

  public disabled = false;
  public showSpinners = true;
  public showSeconds = false;
  public touchUi = false;
  public enableMeridian = false;
  public stepHour = 1;
  public stepMinute = 1;
  public stepSecond = 1;
  noonDateType: any = 'byDay';
  emissionDateType: any = 'byDay';
  //chart 

  lineChartEmissionData1 = [
    {
      "name": 'CO(ppm)',
      "series": [] as any
    },
    {
      "name": 'NOx(ppm)',
      "series": [] as any
    },
    {
      "name": 'SOx(ppm)',
      "series": [] as any
    }
  ];

  lineChartEmissionData2 = [
    {
      "name": 'CO2(%V/V)',
      "series": [] as any
    },
    {
      "name": 'O2(%V/V)',
      "series": [] as any
    },
    {
      "name": 'SPEED(NM)',
      "series": [] as any
    }
  ];
  viewLineChart: any[] = [560, 250];
  showXAxis = true;
  showYAxis = true;
  gradient = false;
  showLegend = false;
  showXAxisLabel = true;
  xAxisLabel = 'Time';
  showYAxisLabel = false;
  legendPosition = 'right';
  colorScheme = {
    domain: ['#5AA454', '#A10A28', '#C7B42C', '#AAAAAA', '#FFA500']
  };

  autoScale = false;
  //chart option end

  date = { fromDate: new Date(), toDate: new Date() };

  ngOnInit(): void {
    this.custom = this.fb.group({
      fromDate: [new Date()],
      toDate: [new Date()]
    })
    this.noonForm = this.fb.group({
      noonDate: [new Date()]
    });
    this.maxDate.setDate(this.maxDate.getDate());
    this.date.fromDate.setHours(0);
    this.date.fromDate.setMinutes(0);
    this.date.fromDate.setSeconds(0);
    if (this.adminDetailService.deviceList.length == 0) {
      this.getVesselList();
    } else {
      this.vesselList = this.adminDetailService.deviceList;
      this.noonVesselSelected = this.vesselList[0].shipId;
      if (this.adminDetailService.status == "ONLINE") {
        this.getSummaryReport(0, this.adminDetailService.userId, this.date.fromDate.getTime(), this.date.toDate.getTime());
      }
    }


  }

  getSummaryReport(shipId: any, userId: any, first: any, last: any) {
    this.reportService.getSummaryReport(shipId, userId, first, last).subscribe(
      (res: any) => {
        if (res) {
          this.summaryList = res.data;
          this.summaryTable = this.summaryList;
          for (let i = 0; i < this.summaryTable.length; i++) {
            var report = this.summaryTable[i];
            var dev = this.getDeviceById(report.shipId);
            if (dev != null) {
              report.shipName = dev.shipName;
            }
          }
          this.generateTable(this.summaryTable);
        }
      }, (err: any) => {
        alert("Data don't get successfully: " + err.error.message);
      }
    )
  }

  resetGraph() {
    this.lineChartEmissionData1 = [
      {
        "name": 'CO(ppm)',
        "series": [] as any
      },
      {
        "name": 'NOx(ppm)',
        "series": [] as any
      },
      {
        "name": 'SOx(ppm)',
        "series": [] as any
      }
    ];
    this.lineChartEmissionData2 = [
      {
        "name": 'CO2(%V/V)',
        "series": [] as any
      },
      {
        "name": 'O2(%V/V)',
        "series": [] as any
      },
      {
        "name": 'SPEED(NM)',
        "series": [] as any
      }
    ];
  }

  exportExcel() {
    this.excelButtonDisabled = true;
    let exportAsConfig: ExportAsConfig = {
      type: 'xlsx',
      elementIdOrContent: 'exportTable'
    }
    // download the file using old school javascript method
    this.exportAsService.save(exportAsConfig, this.reportType).subscribe(() => {
      // save started
      this.excelButtonDisabled = false;
    });
  }
  exportPdf() {
    this.pdfButtonDisabled = true;
    let exportAsConfig: ExportAsConfig = {
      type: 'pdf',
      elementIdOrContent: 'exportTable'
    }
    // download the file using old school javascript method
    this.exportAsService.save(exportAsConfig, this.reportType).subscribe(() => {
      // save started
      this.pdfButtonDisabled = false;
    });
    // get the data as base64 or json object for json type - this will be helpful in ionic or SSR
    // this.exportAsService.get(exportAsConfig).subscribe(content => {
    //   console.log(content);
    // });
  }
  selectedNoonValue() {

    if (this.noonDateType == 'today') {
      let firstDate = new Date();
      this.setToMidNight(firstDate);
      // console.log(firstDate);
      this.date.fromDate = firstDate;
      this.date.toDate = new Date();
      // this.getTripTimeline(this.date.fromDate.getTime(), this.date.toDate.getTime(), this.pageNumber);
    } else if (this.noonDateType == 'last7') {
      let firstDate = new Date();
      firstDate.setDate(firstDate.getDate() - 7);
      this.setToMidNight(firstDate);
      this.date.fromDate = firstDate;
      this.date.toDate = new Date();

    }
  }
  reloadHistory() {

    if (this.dateType == 'today') {
      let firstDate = new Date();
      this.setToMidNight(firstDate);
      // console.log(firstDate);
      this.date.fromDate = firstDate;
      this.date.toDate = new Date();
      // this.getTripTimeline(this.date.fromDate.getTime(), this.date.toDate.getTime(), this.pageNumber);
    } else if (this.dateType == 'last24') {
      let firstDate = new Date();
      firstDate.setHours(firstDate.getHours() - 24);
      this.date.fromDate = firstDate;
      this.date.toDate = new Date();
      // this.getTripTimeline(this.date.fromDate.getTime(), this.date.toDate.getTime(), this.pageNumber);
    } else if (this.dateType == 'last7') {
      let firstDate = new Date();
      firstDate.setDate(firstDate.getDate() - 7);
      this.setToMidNight(firstDate);
      this.date.fromDate = firstDate;
      this.date.toDate = new Date();

    }
  }
  setToMidNight(date: Date) {
    date.setHours(0);
    date.setMinutes(0);
    date.setSeconds(0);
  }

  getVesselList() {
    this.reportService.getVesselList().subscribe(
      (res: any) => {
        if (res.statusCode == 200) {
          this.vesselList = res.data;
          this.noonVesselSelected = this.vesselList[0].shipId;
          this.getSummaryReport(0, this.adminDetailService.userId, this.date.fromDate.getTime(), this.date.toDate.getTime());
        }
      }, (err) => {
        console.log(err.error.message);
      }
    )
  }

  getDeviceById(id: any) {
    let device = null;
    for (var i = 0; i < this.vesselList.length; i++) {
      if (id == this.vesselList[i].shipId) {
        device = this.vesselList[i];
        break;
      }

    }
    return device;
  }

  getNoonByMultipleDays(from: any, to: any, shipId: any) {
    this.reportService.getNewNoonReportByMultipleDays(from, to, shipId).subscribe(
      (res: any) => {
        if (res) {
          this.summaryList = res.data;
          this.noonTable = this.summaryList;
          // for (let i = 0; i < this.noonTable.length; i++) {
          //   var report = this.noonTable[i];

          //   if (this.lineChartEmissionData[0].series.length < 25) {
          //     this.lineChartEmissionData[0].series.push({ "name": this.datePipe.transform(`${report.year}/${report.month}/${report.day}`, 'yyyy-dd-mm'), "value": report.crbnDiOx });
          //     this.lineChartEmissionData[1].series.push({ "name": this.datePipe.transform(`${report.year}/${report.month}/${report.day}`, 'yyyy-dd-mm'), "value": report.oxi });
          //     this.lineChartEmissionData[2].series.push({ "name": this.datePipe.transform(`${report.year}/${report.month}/${report.day}`, 'yyyy-dd-mm'), "value": report.crbnMoOx });
          //     this.lineChartEmissionData[3].series.push({ "name": this.datePipe.transform(`${report.year}/${report.month}/${report.day}`, 'yyyy-dd-mm'), "value": report.nitrOxi });
          //     this.lineChartEmissionData[4].series.push({ "name": this.datePipe.transform(`${report.year}/${report.month}/${report.day}`, 'yyyy-dd-mm'), "value": report.sox });
          //   }
          //   this.lineChartEmissionData = Object.assign([report.crbnDiOx], this.lineChartEmissionData);
          // }

          this.generateTable(this.noonTable);
        }
      }, (err: any) => {
        console.log(err);
        alert("Data don't get successfully: " + err.error.message);
      }
    )
  }

  getEmissionReport(date: any, shipId: any) {
    this.reportService.getEmissionReport(date, shipId).subscribe(
      (res: any) => {
        if (res) {
          this.summaryList = res.data;
          // this.noonTable = this.summaryList;
          this.emissionTable = this.summaryList;
          for (let i = 0; i < this.emissionTable.length; i++) {
            var report = this.emissionTable[i];

            if (this.lineChartEmissionData1[0].series.length < 25) {
              this.lineChartEmissionData1[0].series.push({ "name": this.datePipe.transform(report.deviceTime, 'HH:mm', 'UTC'), "value": report.crbnMoOx });
              this.lineChartEmissionData1[1].series.push({ "name": this.datePipe.transform(report.deviceTime, 'HH:mm', 'UTC'), "value": report.nitrOxi });
              this.lineChartEmissionData1[2].series.push({ "name": this.datePipe.transform(report.deviceTime, 'HH:mm', 'UTC'), "value": report.sox });
              this.lineChartEmissionData2[0].series.push({ "name": this.datePipe.transform(report.deviceTime, 'HH:mm', 'UTC'), "value": report.crbnDiOx });
              this.lineChartEmissionData2[1].series.push({ "name": this.datePipe.transform(report.deviceTime, 'HH:mm', 'UTC'), "value": report.oxi });
              this.lineChartEmissionData2[2].series.push({ "name": this.datePipe.transform(report.deviceTime, 'HH:mm', 'UTC'), "value": report.speed });
            }
            this.lineChartEmissionData1 = Object.assign([report.crbnMoOx], this.lineChartEmissionData1);
            this.lineChartEmissionData2 = Object.assign([report.crbnDiOx], this.lineChartEmissionData2);
          }

          this.generateTable(this.emissionTable);
        }
      }, (err: any) => {
        alert("Data don't get successfully: " + err.error.message);
      }
    )
  }

  generateReport() {
    this.summaryList = [];
    this.summaryTable = [];
    this.noonTable = [];
    this.emissionTable = [];
    this.resetGraph();
    if (this.dateType == 'custom' || this.noonDateType == 'custom') {
      this.date.fromDate = this.custom.value.fromDate;
      this.date.toDate = this.custom.value.toDate;
    }
    if (this.reportType == 'Summary Report') {
      if (this.vesselSelected == '') {
        this.getSummaryReport(0, this.adminDetailService.userId, this.date.fromDate.getTime(), this.date.toDate.getTime(),);
      } else {
        this.getSummaryReport(this.vesselSelected, 0, this.date.fromDate.getTime(), this.date.toDate.getTime());
      }
    }

    if (this.reportType == 'Noon Report') {
      if (this.reportType == 'Noon Report' && this.noonDateType == 'byDay') {
        let date = this.datePipe.transform(this.noonForm.value.noonDate, 'yyyy-MM-dd')
        this.getNoonByMultipleDays(date, date, this.noonVesselSelected);
      }
      else {
        this.getNoonByMultipleDays(this.datePipe.transform(this.date.fromDate, 'yyyy-MM-dd'), this.datePipe.transform(this.date.toDate, 'yyyy-MM-dd'), this.noonVesselSelected);
      }
      // let date = this.datePipe.transform(this.noonForm.value.noonDate, 'yyyy-MM-dd')
      // this.getNewNoonReport(date, this.noonVesselSelected);
    }

    if (this.reportType == 'Emission Report') {
      let date = this.datePipe.transform(this.noonForm.value.noonDate, 'yyyy-MM-dd')
      this.getEmissionReport(date, this.noonVesselSelected);

    }
  }



  getNewNoonReport(date: any, shipId: any) {
    console.log(date);
    console.log(shipId);
    this.reportService.getNewNoonReport(date, shipId).subscribe(
      (res: any) => {
        if (res) {

          this.noonData = res.data;
          console.log(this.noonData);
        }
      }, (err: any) => {
        alert("Data don't get successfully: " + err.error.message);
      }
    )
  }

  public generateTable(tableData: any) {
    this.datasource = new MatTableDataSource(tableData);
  }

  viewNoonReportData(report: any) {
    const dialogConfig = new MatDialogConfig();
    // The user can't close the dialog by clicking outside its body
    dialogConfig.disableClose = false;
    const modalDialog = this.matDialog.open(NoonReportDetailsComponent, {
      // panelClass: 'myapp-no-padding-dialog',
      width: '800px',
      data: report
    });
  }

}
