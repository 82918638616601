import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { AdminDetailService } from 'src/app/shared/admin-detail.service';

@Injectable({
    providedIn: 'root'
})
export class ReportService {
    constructor(private httpClient: HttpClient,
        private adminDetailService: AdminDetailService) { }

    getVesselList() {
        let url = `${this.adminDetailService.API_URL}/device/list?ownerId=${this.adminDetailService.userId}`;
        return this.httpClient.get(url, this.adminDetailService.header);
    }

    getSummaryReport(shipId: any, userId: any, first: any, last: any) {
        let url = `${this.adminDetailService.API_URL}/report/summary?shipId=${shipId}&userId=${userId}&firstDate=${first}&lastDate=${last}`;
        return this.httpClient.get(url, this.adminDetailService.header);
    }

    getNoonReport(date: any, shipId: any) {
        let url = `${this.adminDetailService.API_URL}/report/noon?shipId=${shipId}&date=${date}`;
        return this.httpClient.get(url, this.adminDetailService.header)
    }

    getEmissionReport(date: any, shipId: any) {
        let url = `${this.adminDetailService.API_URL}/report/emission-by-hour?shipId=${shipId}&date=${date}`;
        return this.httpClient.get(url, this.adminDetailService.header)
    }

    getNewNoonReport(date: any, shipId: any) {
        let url = `${this.adminDetailService.API_URL}/report/new-noon?shipId=${shipId}&date=${date}`;
        return this.httpClient.get(url, this.adminDetailService.header)
    }
    getNoonReportByMultipleDays(from: any, to: any, shipId: any) {
        let url = `${this.adminDetailService.API_URL}/report/noon/by-multipleday?shipId=${shipId}&fromDate=${from}&toDate=${to}`;
        return this.httpClient.get(url, this.adminDetailService.header)
    }
    getNewNoonReportByMultipleDays(from: any, to: any, shipId: any) {
        let url = `${this.adminDetailService.API_URL}/report/new-noon?shipId=${shipId}&fromDate=${from}&toDate=${to}`;
        return this.httpClient.get(url, this.adminDetailService.header)
    }
}