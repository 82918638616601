import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { AdminDetailService } from 'src/app/shared/admin-detail.service';

@Injectable({
    providedIn: 'root'
})
export class AddAssignmentService {
    constructor(private httpClient: HttpClient,
        private adminDetailService: AdminDetailService) { }

    getVesselList() {
        let url = `${this.adminDetailService.API_URL}/device/list?ownerId=${this.adminDetailService.userId}`;
        return this.httpClient.get(url, this.adminDetailService.header);
    }

    addAssignment(formData: any) {
        let url = `${this.adminDetailService.API_URL}/voyage/add`;
        return this.httpClient.post(url, formData, this.adminDetailService.header);
    }

}