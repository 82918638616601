import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { AdminDetailService } from 'src/app/shared/admin-detail.service';

@Injectable({
    providedIn: 'root'
})
export class DashboardService {
    constructor(private httpClient: HttpClient,
        private adminDetailService: AdminDetailService) { }

    getVesselList() {
        let url = `${this.adminDetailService.API_URL}/device/list?ownerId=${this.adminDetailService.userId}`;
        return this.httpClient.get(url, this.adminDetailService.header);
    }

    getLiveData() {
        return this.httpClient.get("assets/csvjson.json");
    }

}