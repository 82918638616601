<mat-dialog-content>
    <ng-container >
        <div>
            <div class="row" style="margin-left: 1px; background-color: white;margin-top: 25px;">
                <div class="col-sm-4"> 
                    <span>Vessel Name</span> : <b>{{data.vesselName}}</b>         
                </div>
                <div class="col-sm-4">
                    <span>IMO Number</span> : <b>{{data.imoNumber}}</b>
                </div>
                <div class="col-sm-4">
                    <span>Captain</span> : <b>{{data.captain}}</b>
                </div>
            </div>
        
            <div class="row" style="margin-left: 1px; background-color: white;margin-top: 25px;">
                <div class="col-sm-4"> 
                    <span>Chief Engineer</span> : <b>{{data.chiefEng}}</b>         
                </div>
                <div class="col-sm-4">
                    <span>Report Type</span> : <b>{{data.reportType}}</b>
                </div>
                <div class="col-sm-4">
                    <span>Report Status</span> : <b>{{data.reportStatus}}</b>
                </div>
            </div>
        
            <div class="row" style="margin-left: 1px; background-color: white;margin-top: 25px;">
                <div class="col-sm-4"> 
                    <span>Report Nature</span> : <b>{{data.reportNature}}</b>         
                </div>
                <div class="col-sm-4">
                    <span>Local Time</span> : <b>{{data.localTime | date:'medium'}}</b>
                </div>
                <div class="col-sm-4">
                    <span>Timezone Offset</span> : <b>{{data.timeZoneOffset}}</b>
                </div>
            </div>
        
            <div class="row" style="margin-left: 1px; background-color: white;margin-top: 25px;">
                <div class="col-sm-4"> 
                    <span>Latitude</span> : <b>{{data.lat}}</b>         
                </div>
                <div class="col-sm-4">
                    <span>Longitude</span> : <b>{{data.lng}}</b>
                </div>
                <div class="col-sm-4">
                    <span>Arrival Port</span> : <b>{{data.arrivalPort}}</b>
                </div>
            </div>
        
            <div class="row" style="margin-left: 1px; background-color: white;margin-top: 25px;">
                <div class="col-sm-4"> 
                    <span>Departure Port</span> : <b>{{data.departurePort}}</b>         
                </div>
                <div class="col-sm-4">
                    <span>Voyage</span> : <b>{{data.voyageName}}</b>
                </div>
                <div class="col-sm-4">
                    <span>Voyage Type</span> : <b>{{data.voyageType}}</b>
                </div>
            </div>
        
            <div class="row" style="margin-left: 1px; background-color: white;margin-top: 25px;">
                <div class="col-sm-4"> 
                    <span>Operation Type</span> : <b>{{data.operationType}}</b>         
                </div>
                <div class="col-sm-4">
                    <span>Bunkering Operation</span> : <b>{{data.bunkeringOperation}}</b>
                </div>
                <div class="col-sm-4">
                    <span>Port Sailing From</span> : <b>{{data.sailingPortFrom}}</b>
                </div>
            </div>
        
            <div class="row" style="margin-left: 1px; background-color: white;margin-top: 25px;">
                <div class="col-sm-4"> 
                    <span>Port Sailing To</span> : <b>{{data.sailingPortTo}}</b>         
                </div>
                <div class="col-sm-4">
                    <span>ETA</span> : <b>{{data.etaDate}}</b>
                </div>
                <div class="col-sm-4">
                    <span>Distance To Go</span> : <b>{{data.distanceToGo}} (KM)</b>
                </div>
            </div>
        
            <div class="row" style="margin-left: 1px; background-color: white;margin-top: 25px;">
                <div class="col-sm-4"> 
                    <span>Distance Since Last Report</span> : <b>{{data.distanceTravelledSinceLastReport}} (KM)</b>         
                </div>
                <div class="col-sm-4">
                    <span>Total Cargo</span> : <b>{{data.totalCargoCarried}} (Ton)</b>
                </div>
                <div class="col-sm-4">
                    <span>CO</span> : <b>{{data.co}} (ppm)</b>
                </div>
            </div>
        
            <div class="row" style="margin-left: 1px; background-color: white;margin-top: 25px;">
                <div class="col-sm-4"> 
                    <span>SOX</span> : <b>{{data.sox}} (ppm)</b>         
                </div>
                <div class="col-sm-4">
                    <span>NOX</span> : <b>{{data.nox}} (ppm)</b>
                </div>
                <div class="col-sm-4">
                    <span>CO2</span> : <b>{{data.coTwo}} (%V/V)</b>
                </div>
            </div>
        
            <div class="row" style="margin-left: 1px; background-color: white;margin-top: 25px;">
                <div class="col-sm-4"> 
                    <span>O2</span> : <b>{{data.otwo}} (%V/V)</b>         
                </div>
                <div class="col-sm-4">
                    <span>Report Date</span> : <b>{{data.reportDate | date:'medium'}}</b>
                </div>
                <div class="col-sm-4">
                    <span></span> : <b></b>
                </div>
            </div>
        </div>
    </ng-container>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>Close</button>
</mat-dialog-actions>
