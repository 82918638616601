import { ViewEmissionComponent } from './view-emission/view-emission.component';
import { AddBunkeringComponent } from './add-bunkering/add-bunkering.component';
import { AdminDetailService } from './../../shared/admin-detail.service';
import { AddAssignmentComponent } from './add-assignment/add-assignment.component';
import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { AssignmentService } from './assignment.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { ExportAsConfig, ExportAsService } from 'ngx-export-as';
import { AddRedeliveryComponent } from './add-redelivery/add-redelivery.component';
import { UpdateAssignmentComponent } from './update-assignment/update-assignment.component';

@Component({
  selector: 'app-assignment',
  templateUrl: './assignment.component.html',
  styleUrls: ['./assignment.component.css']
})
export class AssignmentComponent implements OnInit {

  constructor(public matDialog: MatDialog,
    private assignmentService: AssignmentService,
    private fb: FormBuilder,
    private adminDetailService: AdminDetailService,
    private exportAsService: ExportAsService) {
    this.adminDetailService.title = "Voyage";
  }

  displaySignature = false;
  pdfButtonDisabled = false;
  excelButtonDisabled = false;
  datasource: any;
  vesselSelected = "";
  public totalElements: number = 0;
  public pageIndex: number = 0;
  public pageNumber = 0;
  vesselList: any = [];
  date = { fromDate: new Date, toDate: new Date }
  assignmentList: any = [];
  dateRange!: FormGroup;
  dateType = 'today';
  ngOnInit(): void {
    if (this.adminDetailService.deviceList.length == 0) {
      this.getVesselList();
    } else {
      this.vesselList = this.adminDetailService.deviceList;
      this.vesselSelected = this.vesselList[0].shipId;
      if (this.adminDetailService.status == "ONLINE") {
        this.reloadHistory();
      }
    }
    this.dateRange = this.fb.group({
      fromDate: [new Date],
      toDate: [new Date]
    })


  }

  displayedColumns: string[] = ['voyageNo', 'vesselName', 'charterer', 'charterType', 'dateOfDeparture', 'termOfDelivery', 'placeOfDelivery', 'dateOfDelivery', 'fuelTypeInit', 'bunkerOnBoardInit', 'loadport',
    'dischargePort', 'bunkeringAt', 'bunkerProvider', 'bunkeringDate', 'fuelTypeBnk',
    'bunkerOnBoardBnk', 'redeliveryAt', 'redeliveryDate', 'bunkerOnBoardRed'];

  getAssignmentList(pageNumber: any, first: any, last: any) {
    this.assignmentService.getAssignmentList(pageNumber, first, last, this.vesselSelected).subscribe(
      (res: any) => {
        if (res.statusCode == 200) {
          this.assignmentList = res.data.content;
          this.generateTable();
        }
      }, (err) => {
        alert("Data don't get successfully: " + err.error.message);
      }
    )
  }

  getVesselList() {
    this.assignmentService.getVesselList().subscribe(
      (res: any) => {
        if (res.statusCode == 200) {
          this.vesselList = res.data;
          this.vesselSelected = this.vesselList[0].shipId;
          this.reloadHistory();
        }
      }, (err) => {
        console.log(err.error.message);
      }
    )
  }

  changeDevice() {
    this.reloadHistory();
  }

  viewEmissionData(voyage: any) {
    const dialogConfig = new MatDialogConfig();
    // The user can't close the dialog by clicking outside its body
    dialogConfig.disableClose = true;
    const modalDialog = this.matDialog.open(ViewEmissionComponent, {
      // panelClass: 'myapp-no-padding-dialog',
      width: '500px',
      data: voyage
    });
  }

  openAssignmentForm() {
    const dialogConfig = new MatDialogConfig();
    // The user can't close the dialog by clicking outside its body
    dialogConfig.disableClose = true;
    const modalDialog = this.matDialog.open(AddAssignmentComponent, {
      // panelClass: 'myapp-no-padding-dialog',
      width: '500px',
    });
    modalDialog.afterClosed().subscribe(
      (res) => {
        console.log(res);
        this.vesselSelected = res.vessel;
        this.date.toDate.setDate(res.deptDate.getDate() + 2);
        this.getAssignmentList(this.pageNumber, this.date.fromDate.getTime(), this.date.toDate.getTime());

      }
    )
  }
  openBunkeringForm(voyageId: any) {
    const dialogConfig = new MatDialogConfig();
    // The user can't close the dialog by clicking outside its body
    dialogConfig.disableClose = true;
    const modalDialog = this.matDialog.open(AddBunkeringComponent, {
      // panelClass: 'myapp-no-padding-dialog',
      width: '500px',
      data: voyageId
    });
    modalDialog.afterClosed().subscribe(
      (res) => {
        // this.date.toDate.setDate(res.deptDate.getDate() + 2);
        this.getAssignmentList(this.pageNumber, this.date.fromDate.getTime(), this.date.toDate.getTime());
      }
    )
  }

  openUpdateAssignmentForm(voyage: any) {
    const dialogConfig = new MatDialogConfig();
    // The user can't close the dialog by clicking outside its body
    dialogConfig.disableClose = true;
    const modalDialog = this.matDialog.open(UpdateAssignmentComponent, {
      // panelClass: 'myapp-no-padding-dialog',
      width: '500px',
      data: voyage
    });
    modalDialog.afterClosed().subscribe(
      (res) => {
        this.reloadHistory();
      }
    )
  }
  openRedeliveryForm(voyageId: any) {
    const dialogConfig = new MatDialogConfig();
    // The user can't close the dialog by clicking outside its body
    dialogConfig.disableClose = true;
    const modalDialog = this.matDialog.open(AddRedeliveryComponent, {
      // panelClass: 'myapp-no-padding-dialog',
      width: '500px',
      data: voyageId
    });
    modalDialog.afterClosed().subscribe(
      (res) => {
        // this.date.toDate.setDate(this.date.toDate.getDate() + 2);
        this.getAssignmentList(this.pageNumber, this.date.fromDate.getTime(), this.date.toDate.getTime());
      }
    )
  }

  exportExcel() {
    this.excelButtonDisabled = true;
    let exportAsConfig: ExportAsConfig = {
      type: 'xlsx',
      elementIdOrContent: 'exportTable',

    }
    // download the file using old school javascript method
    this.exportAsService.save(exportAsConfig, 'Voyage Details').subscribe(() => {
      // save started
      this.excelButtonDisabled = false;
    });
    setTimeout(() => {

    }, 5000)
  }
  exportPdf() {
    this.pdfButtonDisabled = true;
    this.displaySignature = true
    let exportAsConfig: ExportAsConfig = {
      type: 'pdf',
      elementIdOrContent: 'exportTable',
      options: {
        jsPDF: {
          format: 'a1',
        }

      }
    }
    // download the file using old school javascript method
    this.exportAsService.save(exportAsConfig, 'Voyage Details').subscribe(() => {
      // save started
      this.displaySignature = false;
      this.pdfButtonDisabled = false;
    });
    // get the data as base64 or json object for json type - this will be helpful in ionic or SSR
    // this.exportAsService.get(exportAsConfig).subscribe(content => {
    //   console.log(content);
    // });
    setTimeout(() => {


    }, 5000)
  }

  reloadHistory() {
    if (this.dateType == 'today') {
      let firstDate = new Date();
      this.setToMidNight(firstDate);
      // console.log(firstDate);
      this.date.fromDate = firstDate;
      this.date.toDate = new Date();
      this.getAssignmentList(this.pageNumber, this.date.fromDate.getTime(), this.date.toDate.getTime());
    } else if (this.dateType == 'last24') {
      let firstDate = new Date();
      firstDate.setHours(firstDate.getHours() - 24);
      this.date.fromDate = firstDate;
      this.date.toDate = new Date();
      this.getAssignmentList(this.pageNumber, this.date.fromDate.getTime(), this.date.toDate.getTime());
    } else if (this.dateType == 'last7') {
      let firstDate = new Date();
      firstDate.setDate(firstDate.getDate() - 7);
      this.setToMidNight(firstDate);
      this.date.fromDate = firstDate;
      this.date.toDate = new Date();
      this.getAssignmentList(this.pageNumber, this.date.fromDate.getTime(), this.date.toDate.getTime());

    }
  }

  setToMidNight(date: Date) {
    date.setHours(0);
    date.setMinutes(0);
    date.setSeconds(0);
  }

  onPageChange(ev: any) {
    this.pageNumber = ev.pageIndex;
    this.getAssignmentList(this.pageNumber, this.date.fromDate.getTime(), this.date.toDate.getTime());
  }

  applyDate() {
    this.date.fromDate = this.dateRange.value.fromDate;
    this.date.toDate = this.dateRange.value.toDate;
    this.getAssignmentList(this.pageNumber, this.date.fromDate.getTime(), this.date.toDate.getTime());
  }

  public generateTable() {
    this.datasource = new MatTableDataSource(this.assignmentList);
  }

}
