<div class="row">
    <div class="col-lg-12">
        <mat-card style="margin-top: 10px;">
            <mat-card-content>
                <h4><strong>Hello {{adminDetailService.username}}</strong></h4>
            </mat-card-content>
        </mat-card>

        <div class="row" style="margin-top: 10px;">
            <div class="col-lg-12">
                <!-- <mat-form-field style="background-color: white;padding-left: 5px ; padding-top: 5px; width: 100%;">
                    <mat-label>Search</mat-label>
                    <input matInput (keyup)="applyFilter($event.target.value)" placeholder="ex- simId">
                  </mat-form-field> -->
                <div class="example-table-container" style="overflow: auto;height: 480px;">
                    <table mat-table [dataSource]="datasource" class="mat-elevation-z8" style="width: 100%;">
                        <ng-container matColumnDef="vesselType">
                            <th mat-header-cell *matHeaderCellDef>Vessel Type </th>
                            <td mat-cell *matCellDef="let element">
                                {{element.shipType}} </td>
                        </ng-container>

                        <ng-container matColumnDef="vesselName">
                            <th mat-header-cell *matHeaderCellDef> Vessel Name </th>
                            <td mat-cell *matCellDef="let element">
                                {{element.shipName}} </td>
                        </ng-container>

                        <ng-container matColumnDef="imoNumber">
                            <th mat-header-cell *matHeaderCellDef> IMO NUMBER </th>
                            <td mat-cell *matCellDef="let element">
                                {{element.imoNumber}} </td>
                        </ng-container>

                        <ng-container matColumnDef="dwt">
                            <th mat-header-cell *matHeaderCellDef> DWT </th>
                            <td mat-cell *matCellDef="let element">
                                {{element.dwt}} </td>
                        </ng-container>

                        <ng-container matColumnDef="grt">
                            <th mat-header-cell *matHeaderCellDef>GRT </th>
                            <td mat-cell *matCellDef="let element">
                                {{element.grt}} </td>
                        </ng-container>

                        <ng-container matColumnDef="nrt">
                            <th mat-header-cell *matHeaderCellDef> NRT </th>
                            <td mat-cell *matCellDef="let element">
                                {{element.nrt}} </td>
                        </ng-container>

                        <ng-container matColumnDef="built">
                            <th mat-header-cell *matHeaderCellDef> Built Year</th>
                            <td mat-cell *matCellDef="let element">
                                {{element.builtYear}} </td>
                        </ng-container>

                        <ng-container matColumnDef="piClub">
                            <th mat-header-cell *matHeaderCellDef> P&I Club </th>
                            <td mat-cell *matCellDef="let element">
                                {{element.insuranceCompany}} </td>
                        </ng-container>

                        <ng-container matColumnDef="flag">
                            <th mat-header-cell *matHeaderCellDef> Flag</th>
                            <td mat-cell *matCellDef="let element">
                                {{element.flag}} </td>
                        </ng-container>

                        <ng-container matColumnDef="engine">
                            <th mat-header-cell *matHeaderCellDef> Engine </th>
                            <td mat-cell *matCellDef="let element">
                                {{element.engineMaker}} </td>
                        </ng-container>

                        <ng-container matColumnDef="turboCarger">
                            <th mat-header-cell *matHeaderCellDef> Turbo Charger</th>
                            <td mat-cell *matCellDef="let element">
                                {{element.turboChargerMaker}} </td>
                        </ng-container>

                        <ng-container matColumnDef="button">
                            <th mat-header-cell *matHeaderCellDef><button mat-stroked-button color="primary"
                                    (click)="addVesselForm()">Add
                                    Vessel</button> </th>
                            <td mat-cell *matCellDef="let element">
                                <button mat-stroked-button color="primary" disabled>Edit</button>
                                <button mat-stroked-button color="warn" disabled>Disable</button>

                            </td>
                        </ng-container>

                        <tr mat-header-row class="header" *matHeaderRowDef="displayedColumns"
                            style="background: lightgrey;"></tr>

                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                    </table>

                    <mat-paginator [pageSize]="20" [length]='totalElements' (page)="onPageChange($event)"
                        style="width: 100%;">
                    </mat-paginator>
                </div>
            </div>
        </div>
    </div>
</div>