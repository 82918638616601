import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { pipe } from 'rxjs';
import { AdminDetailService } from 'src/app/shared/admin-detail.service';
import { LoginService } from './login.service';
import { mergeMap } from 'rxjs/operators';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  constructor(private fb: FormBuilder,
    private router: Router,
    private loginService: LoginService,
    private adminDetailService: AdminDetailService) { }

  public msg!: string;
  loginForm!: FormGroup;
  ngOnInit(): void {
    this.loginForm = this.fb.group({
      username: ['', Validators.required],
      password: ['', Validators.required],

    })
  }
  userLogin() {
    this.loginService.userLogin(this.loginForm.value.username, this.loginForm.value.password).pipe(
      mergeMap((res: any) => {
        if (res.status == 200) {
          console.log(res);
          this.adminDetailService.updateSessionData(res.body);
          this.router.navigateByUrl('main/home');

        }
        else if (res.status >= 400) {
          if (res.data.error_description == "User is disabled") {
            this.msg = "Account has been blocked! Please contact us. Phone: +91-9910482275 or Email: support@iotasmart.com";
            setTimeout(() => {
              this.msg = '';
            }, 3000)
          } else {
            this.msg = "Unauthorized! Please login with valid credential.";
            setTimeout(() => {
              this.msg = '';
            }, 3000)
          }
        };
        (err: any) => {
          if (err.status >= 400) {
            if (err.error.error_description == "User is disabled") {
              this.msg = "Account has been blocked! Please contact us. Phone: +91-9910482275 or Email: support@iotasmart.com";
              setTimeout(() => {
                this.msg = '';
              }, 3000)
            } else {
              this.msg = "Unauthorized! Please login with valid credential.";
              setTimeout(() => {
                this.msg = '';
              }, 3000)
            }
          }
        }
        return this.loginService.getUserContext(res.body.userId, res.body.access_token);
      })
    ).subscribe(
      (res: any) => {
        console.log(res);
        this.adminDetailService.updateAdminData(res);

      }, (err: any) => {
        console.log(err);
      }
    )

  }

}


