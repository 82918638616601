import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AdminDetailService } from 'src/app/shared/admin-detail.service';
import { AddRedeliveryService } from './add-redelivery.service';

@Component({
  selector: 'app-add-redelivery',
  templateUrl: './add-redelivery.component.html',
  styleUrls: ['./add-redelivery.component.css']
})
export class AddRedeliveryComponent implements OnInit {

  constructor(private fb: FormBuilder,
    public dialogRef: MatDialogRef<AddRedeliveryComponent>,
    private adminDetailService: AdminDetailService,
    private addRedeliveryService: AddRedeliveryService,
    private datePipe: DatePipe,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  addRedeliveryForm !: FormGroup;

  ngOnInit(): void {
    this.addRedeliveryForm = this.fb.group({
      redeliveryAt: [null, Validators.required],
      redeliveryDate: [new Date, Validators.required],
      ifoBoard: [0],
      mdoBoard: [0],
    })
  }

  addRedelivery() {
    var formData: any = {};
    formData.bunkerOnBoardIfoRed = this.addRedeliveryForm.value.ifoBoard;
    formData.bunkerOnBoardMdoRed = this.addRedeliveryForm.value.mdoBoard;
    formData.redeliveryAt = this.addRedeliveryForm.value.redeliveryAt;
    formData.voyageId = this.data;
    formData.redeliveryDate = this.datePipe.transform(this.addRedeliveryForm.value.redeliveryDate, 'yyyy-MM-dd HH:mm:ss');
    this.addRedeliveryService.addRedelivery(formData).subscribe(
      (res: any) => {
        if (res.statusCode == 200) {
          console.log(res);
          alert("Re-Delivery details Added Successfully");
          this.dialogRef.close();
        }

      }, (err) => {
        alert("Redelivery data don't added successfully: " + err.error.message);
      }
    )
  }

  closeModal() {
    this.dialogRef.close();
  }

}
