import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { AdminDetailService } from 'src/app/shared/admin-detail.service';

@Injectable({
    providedIn: 'root'
})
export class AssignmentService {
    constructor(private httpClient: HttpClient,
        private adminDetailService: AdminDetailService) { }

    getAssignmentList(pageNumber: any, first: any, last: any, shipId: any) {
        // const options = { headers: new HttpHeaders().set('token', this.adminDetailService.) }
        let url = `${this.adminDetailService.API_URL}/voyage/list/by-ship?shipId=${shipId}&pageNumber=${pageNumber}&firstDate=${first}&lastDate=${last}`;
        return this.httpClient.get(url, this.adminDetailService.header);
    }

    getVesselList() {
        let url = `${this.adminDetailService.API_URL}/device/list?ownerId=${this.adminDetailService.userId}`;
        return this.httpClient.get(url, this.adminDetailService.header);
    }
}