import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { AdminDetailService } from 'src/app/shared/admin-detail.service';
import { AddProfileService } from './add-profile.service';

@Component({
  selector: 'app-add-profile',
  templateUrl: './add-profile.component.html',
  styleUrls: ['./add-profile.component.css']
})
export class AddProfileComponent implements OnInit {
  addProfileForm!: FormGroup;
  constructor(private fb: FormBuilder,
    public dialogRef: MatDialogRef<AddProfileComponent>,
    private addProfileService: AddProfileService,
    private adminDetailService: AdminDetailService,
    private datePipe: DatePipe) { }

  startYear = 1980;
  currentYear = new Date().getFullYear();
  yearList: any = [];

  groupTypeList: any = [];
  shipTypeList = ['Aframax Tanker', 'Capesize Bulk Carrier', 'Handysize Bulker', 'Kamsarmax Bulker', 'Post-Panamax Bulker', 'Supramax Bulk Carrier', 'Ultramax Bulker', 'VLCC', 'Custom'];

  ngOnInit(): void {
    while (this.startYear <= this.currentYear) {
      this.yearList.push(this.startYear++);
    }
    this.addProfileForm = this.fb.group({
      shipType: [this.shipTypeList[0], Validators.required],
      shipName: ['', Validators.required],
      teu: [null, [Validators.min(0), Validators.required]],
      imoNumber: [null, [Validators.min(0), Validators.required]],
      deviceId: [''],
      piClub: [null],
      customShipType: [null],
      nrt: [0, [Validators.min(0)]],
      grt: [0, [Validators.min(0)]],
      dwt: [null],
      engine: [''],
      turboCharger: [''],
      builtYear: [this.yearList[0]],
      lastSpecialSurvey: [new Date],
      flag: ['Hong Kong', Validators.required],
    })

  }

  addVessel() {
    var formData: any = {};
    if (this.addProfileForm.value.shipType == 'Custom') {
      formData.shipType = this.addProfileForm.value.customShipType;
    } else {
      formData.shipType = this.addProfileForm.value.shipType;
    }
    formData.dateOfLastSurbeyStr = this.datePipe.transform(this.addProfileForm.value.lastSpecialSurvey, 'yyyy-MM-dd');
    formData.shipName = this.addProfileForm.value.shipName;
    formData.turboChargerMaker = this.addProfileForm.value.turboCharger;
    formData.deviceId = this.addProfileForm.value.deviceId;
    formData.dwt = this.addProfileForm.value.dwt;
    formData.builtYear = this.addProfileForm.value.builtYear;
    formData.engineMaker = this.addProfileForm.value.engine;
    formData.flag = this.addProfileForm.value.flag;
    formData.userId = this.adminDetailService.userId;
    formData.grt = this.addProfileForm.value.grt;
    formData.nrt = this.addProfileForm.value.nrt;
    formData.imoNumber = this.addProfileForm.value.imoNumber;
    formData.insuranceCompany = this.addProfileForm.value.piClub;
    formData.teu = this.addProfileForm.value.teu;
    this.addProfileService.addVessel(formData).subscribe(
      (res: any) => {
        if (res.statusCode == 200) {
          console.log(res);
          alert("Vessel Added Successfully");
          this.dialogRef.close();
        }

      }, (err) => {
        alert("Vessel don't added successfully: " + err.error.message);
      }
    )

  }

  closeModal() {
    this.dialogRef.close();
  }

}
