<mat-card style="overflow: hidden;">
    <mat-card-title style="border-bottom: 2px solid #dae0e8; overflow: hidden;">
        <h2 style="margin: 0 0 5px;">Add Bunkering</h2>
    </mat-card-title>
    <mat-card-content style="overflow-y: auto;height: 450px;">
        <form [formGroup]="addBunkeringForm">

            <mat-form-field class="example-full-width">
                <mat-label>Bunkering At</mat-label>
                <input matInput formControlName="bunkeringAt">
            </mat-form-field>
            <mat-form-field class="example-full-width">
                <mat-label>Bunker Provider</mat-label>
                <input matInput formControlName="bunkerProvider">
            </mat-form-field>
            <mat-form-field class="example-full-width" appearance="fill">
                <mat-label>Bunkering Date</mat-label>
                <input matInput [matDatepicker]="picker1" formControlName="bunkeringDate">
                <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                <mat-datepicker #picker1></mat-datepicker>
            </mat-form-field>
            <mat-label>Type Of Fuel</mat-label>
            <mat-form-field class="example-full-width">
                <mat-label>IFO</mat-label>
                <input matInput formControlName="ifoFuel">
            </mat-form-field>
            <mat-form-field class="example-full-width">
                <mat-label>MDO</mat-label>
                <input matInput formControlName="mdoFuel">
            </mat-form-field>
            <mat-label> Bunker On Board (MT)</mat-label>
            <mat-form-field class="example-full-width">
                <mat-label>IFO(MT)</mat-label>
                <input matInput formControlName="ifoBoard">
            </mat-form-field>
            <mat-form-field class="example-full-width">
                <mat-label>MDO(MT)</mat-label>
                <input matInput formControlName="mdoBoard">
            </mat-form-field>


            <div class="text-right">
                <button mat-raised-button color="primary" [disabled]="!addBunkeringForm.valid"
                    style="margin-right: 1px;" (click)="addBunkering()">Submit
                </button>
                <button mat-raised-button color="warn" style="margin-right: 10px;" (click)="closeModal()">Cancel
                </button>
            </div>

        </form>
    </mat-card-content>
</mat-card>