import { AddProfileComponent } from './add-profile/add-profile.component';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { AdminDetailService } from 'src/app/shared/admin-detail.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ProfileService } from './profile.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {
  datasource: any;
  public totalElements: number = 0;
  public pageIndex: number = 0;
  @ViewChild(MatPaginator)
  paginator!: MatPaginator;
  @ViewChild(MatSort)
  sort!: MatSort;

  constructor(public adminDetailService: AdminDetailService,
    private fb: FormBuilder,
    public matDialog: MatDialog,
    private profileService: ProfileService,
  ) {
    this.adminDetailService.title = "Profile";
  }

  displayedColumns: string[] = ['vesselType', 'vesselName', 'imoNumber', 'dwt', 'built', 'piClub', 'grt', 'nrt', 'engine', 'turboCarger', 'flag', 'button']
  vesselList: any = [];

  ngOnInit(): void {
    if (this.adminDetailService.deviceList.length == 0) {
      this.getVesselList();
    } else {
      this.vesselList = this.adminDetailService.deviceList;
      this.generateTable();
      this.datasource.sort = this.sort;
    }

  }

  getVesselList() {
    this.profileService.getVesselList().subscribe(
      (res: any) => {
        if (res.statusCode == 200) {
          this.vesselList = res.data;
          this.generateTable();
          this.datasource.sort = this.sort;
        }
      }, (err) => {
        alert("Data don't get successfully: " + err.error.message);
      }
    )
  }

  addVesselForm() {
    const dialogConfig = new MatDialogConfig();
    // The user can't close the dialog by clicking outside its body
    dialogConfig.disableClose = true;
    const modalDialog = this.matDialog.open(AddProfileComponent, {
      // panelClass: 'myapp-no-padding-dialog',
      width: '500px',
    });
    modalDialog.afterClosed().subscribe(
      (res) => {
        this.getVesselList();

      }
    )
  }

  public generateTable() {
    this.datasource = new MatTableDataSource(this.vesselList);
  }

  onPageChange(ev: any) { }
}
