import { AddBunkeringService } from './add-bunkering.service';
import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AdminDetailService } from 'src/app/shared/admin-detail.service';

@Component({
  selector: 'app-add-bunkering',
  templateUrl: './add-bunkering.component.html',
  styleUrls: ['./add-bunkering.component.css']
})
export class AddBunkeringComponent implements OnInit {

  constructor(private fb: FormBuilder,
    public dialogRef: MatDialogRef<AddBunkeringComponent>,
    private adminDetailService: AdminDetailService,
    private datePipe: DatePipe,
    private addBunkeringService: AddBunkeringService,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  addBunkeringForm !: FormGroup;

  ngOnInit(): void {
    this.addBunkeringForm = this.fb.group({
      bunkeringAt: [null, Validators.required],
      bunkerProvider: [null, Validators.required],
      ifoFuel: [null],
      mdoFuel: [null],
      ifoBoard: [0],
      mdoBoard: [0],
      bunkeringDate: [new Date]
    })
  }

  addBunkering() {
    var formData: any = {};
    formData.bunkerOnBoardIfoBnk = this.addBunkeringForm.value.ifoBoard;
    formData.bunkerOnBoardMdoBnk = this.addBunkeringForm.value.mdoBoard;
    formData.bunkerProvider = this.addBunkeringForm.value.bunkerProvider;
    formData.bunkeringAt = this.addBunkeringForm.value.bunkeringAt;
    formData.fuelTypeIfoBnk = this.addBunkeringForm.value.ifoFuel;
    formData.fuelTypeMdoBnk = this.addBunkeringForm.value.mdoFuel;
    formData.voyageId = this.data;
    formData.bunkeringDate = this.datePipe.transform(this.addBunkeringForm.value.bunkeringDate, 'yyyy-MM-dd HH:mm:ss');
    this.addBunkeringService.addBunkering(formData).subscribe(
      (res: any) => {
        if (res.statusCode == 200) {
          console.log(res);
          alert("Bunkering Details Added Successfully");
          this.dialogRef.close();
        }

      }, (err) => {
        alert("Bunker don't added successfully: " + err.error.message);
      }
    )
  }

  closeModal() {
    this.dialogRef.close();
  }

}
