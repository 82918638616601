<mat-card style="overflow: hidden;">
    <mat-card-title style="border-bottom: 2px solid #dae0e8; overflow: hidden;">
        <h2 style="margin: 0 0 5px;">Add Vessel</h2>
    </mat-card-title>
    <mat-card-content style="overflow-y: auto;height: 450px;">
        <form [formGroup]="addProfileForm">
            <mat-form-field class="example-full-width">
                <mat-label>Vessel Type</mat-label>
                <mat-select formControlName="shipType" required>
                    <mat-option *ngFor="let ship of shipTypeList" [value]="ship">
                        {{ship}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field class="example-full-width" *ngIf="addProfileForm.get('shipType').value == 'Custom'">
                <mat-label>Custom Vessel Type</mat-label>
                <input matInput formControlName="customShipType">
            </mat-form-field>
            <mat-form-field class="example-full-width">
                <mat-label>Vessel Name</mat-label>
                <input matInput formControlName="shipName">
            </mat-form-field>
            <mat-form-field class="example-full-width">
                <mat-label>IMO Number</mat-label>
                <input matInput formControlName="imoNumber">
            </mat-form-field>
            <mat-form-field class="example-full-width">
                <mat-label>P&I Club</mat-label>
                <input matInput formControlName="piClub">
            </mat-form-field>
            <mat-form-field class="example-full-width">
                <mat-label>GRT(Gross Registered Tonnage)</mat-label>
                <input matInput formControlName="grt" type="number">
            </mat-form-field>
            <mat-form-field class="example-full-width">
                <mat-label>NRT(Net Registered Tonnage)</mat-label>
                <input matInput formControlName="nrt" type="number">
            </mat-form-field>
            <mat-form-field class="example-full-width">
                <mat-label>Teu</mat-label>
                <input matInput formControlName="teu" type="number">
            </mat-form-field>
            <mat-form-field class="example-full-width">
                <mat-label>Engine Maker</mat-label>
                <input matInput formControlName="engine">
            </mat-form-field>
            <mat-form-field class="example-full-width">
                <mat-label>DWT</mat-label>
                <input matInput formControlName="dwt" type="number">
            </mat-form-field>
            <mat-form-field class="example-full-width">
                <mat-label>Device Id</mat-label>
                <input matInput formControlName="deviceId">
            </mat-form-field>
            <mat-form-field class="example-full-width">
                <mat-label>Built Year</mat-label>
                <mat-select formControlName="builtYear" required>
                    <mat-option *ngFor="let year of yearList" [value]="year">
                        {{year}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field class="example-full-width">
                <mat-label>Turbo Charger Marker</mat-label>
                <input matInput formControlName="turboCharger">
            </mat-form-field>
            <mat-form-field class="example-full-width" appearance="fill">
                <mat-label>Date Of Last Special Survey</mat-label>
                <input matInput [matDatepicker]="picker" formControlName="lastSpecialSurvey">
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
            <mat-form-field class="example-full-width">
                <mat-label>Flag</mat-label>
                <mat-select formControlName="flag" required>
                    <mat-option value="Hong Kong">Hong Kong</mat-option>
                    <mat-option value="India">India</mat-option>
                    <mat-option value="Ireland">Ireland</mat-option>
                    <mat-option value="USA">USA</mat-option>
                </mat-select>
            </mat-form-field>
            <div class="text-right">
                <button mat-raised-button color="primary" [disabled]="!addProfileForm.valid" style="margin-right: 1px;"
                    (click)="addVessel()">Submit
                </button>
                <button mat-raised-button color="warn" style="margin-right: 10px;" (click)="closeModal()">Cancel
                </button>
            </div>

        </form>
    </mat-card-content>
</mat-card>