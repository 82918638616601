<div class="fleet-bar-div" style="padding-top: 5px;">
    <div class="row">
        <div class="col-lg-12 col-md-12 text-center">
            <div class="row" style="padding:5px 0px 5px 0px;">
                <div class="col-sm-3 col-md-3">
                    <mat-form-field class="example-full-width" appearance="fill">
                        <mat-label>Report Type: </mat-label>
                        <mat-select [(value)]="reportType">
                            <mat-option [value]="report" *ngFor="let report of reportTypeList">
                                {{report}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-sm-3 col-md-3" *ngIf="reportType == 'Summary Report'">
                    <mat-form-field class="example-full-width" appearance="fill">
                        <mat-label>Vessel Name: </mat-label>
                        <mat-select [(value)]="vesselSelected">
                            <mat-option value="">
                                All Vessel
                            </mat-option>
                            <mat-option [value]="vessel.shipId" *ngFor="let vessel of vesselList">
                                {{vessel.shipName}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-sm-3 col-md-3" *ngIf="reportType == 'Noon Report' || reportType == 'Emission Report'">
                    <mat-form-field class="example-full-width" appearance="fill">
                        <mat-label>Vessel Name: </mat-label>
                        <mat-select [(value)]="noonVesselSelected">
                            <mat-option [value]="vessel.shipId" *ngFor="let vessel of vesselList">
                                {{vessel.shipName}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>

            <div class="row">
                <div class="col-sm-3 col-md-3">
                    <div *ngIf="reportType=='Summary Report'">
                        <mat-form-field class="example-full-width" appearance="fill">
                            <mat-label>Date and Time: </mat-label>
                            <mat-select [(value)]="dateType" (selectionChange)="reloadHistory()">
                                <mat-option value="today">
                                    Today
                                </mat-option>
                                <mat-option value="last24">
                                    Last 24 Hours
                                </mat-option>
                                <mat-option value="last7">
                                    Last 7 Days
                                </mat-option>
                                <mat-option value="custom">
                                    Custom Date
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div *ngIf="reportType=='Noon Report'">
                        <mat-form-field class="example-full-width" appearance="fill">
                            <mat-label>Date and Time: </mat-label>
                            <mat-select [(value)]="noonDateType" (selectionChange)="selectedNoonValue()">
                                <mat-option value="byDay">
                                    By Day
                                </mat-option>
                                <mat-option value="last7">
                                    Last 7 Days
                                </mat-option>
                                <mat-option value="custom">
                                    Custom Date
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div *ngIf="reportType=='Emission Report'">
                        <mat-form-field class="example-full-width" appearance="fill">
                            <mat-label>Date and Time: </mat-label>
                            <mat-select [(value)]="emissionDateType">
                                <mat-option value="byDay">
                                    By Day
                                </mat-option>
                                <!-- <mat-option value="last7">
                                    Last 7 Days
                                </mat-option>
                                <mat-option value="custom">
                                    Custom Date
                                </mat-option> -->
                            </mat-select>
                        </mat-form-field>
                    </div>

                </div>
                <div class="col-sm-3 col-md-3" *ngIf="(noonDateType=='byDay' && reportType == 'Noon Report') || reportType == 'Emission Report'">
                    <form [formGroup]="noonForm">
                        <mat-form-field class="example-full-width" appearance="fill">
                            <mat-label>Choose a date</mat-label>
                            <input matInput [matDatepicker]="picker" [max]="maxDate" formControlName="noonDate">
                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                            <mat-datepicker [dateClass]="dateClass" #picker></mat-datepicker>
                        </mat-form-field>
                    </form>
                </div>

                <div class="col-sm-2 col-md-2 text-left">
                    <button mat-raised-button color="warn" (click)="generateReport()">Generate Report</button>
                </div>
                <div class="col-sm-2 col-md-2 text-left">
                    <button mat-raised-button color="primary" (click)="exportExcel()"
                        [disabled]="excelButtonDisabled">Export Excel</button>
                </div>
                <div class="col-sm-2 col-md-2 text-left">
                    <button mat-raised-button color="primary" [disabled]="pdfButtonDisabled"
                        (click)="exportPdf()">Export Pdf</button>
                </div>


            </div>
            <div *ngIf="dateType == 'custom' || noonDateType=='custom'">
                <form [formGroup]="custom">
                    <div class="row">
                        <div class="col-sm-3">
                            <mat-form-field class="example-full-width" appearance="fill">
                                <mat-label>From Date </mat-label>
                                <input matInput formControlName="fromDate" [max]="maxDate"
                                    [ngxMatDatetimePicker]="fromPicker">
                                <mat-datepicker-toggle matSuffix [for]="fromPicker"></mat-datepicker-toggle>
                                <ngx-mat-datetime-picker #fromPicker [showSeconds]="showSeconds" [stepHour]="stepHour"
                                    [stepMinute]="stepMinute" [stepSecond]="stepSecond" [touchUi]="touchUi">
                                </ngx-mat-datetime-picker>
                            </mat-form-field>
                        </div>
                        <div class="col-sm-3">
                            <mat-form-field class="example-full-width" appearance="fill">
                                <mat-label>To Date </mat-label>
                                <input matInput formControlName="toDate" [max]="maxDate"
                                    [ngxMatDatetimePicker]="toPicker">
                                <mat-datepicker-toggle matSuffix [for]="toPicker"></mat-datepicker-toggle>
                                <ngx-mat-datetime-picker #toPicker [showSeconds]="showSeconds" [stepHour]="stepHour"
                                    [stepMinute]="stepMinute" [stepSecond]="stepSecond" [touchUi]="touchUi">
                                </ngx-mat-datetime-picker>
                            </mat-form-field>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
<div class="text-center text-middle" style="font-size: 15px;padding-top: 15px;" *ngIf="summaryList.length == 0">
    Report Not found!
</div>
<ng-container *ngIf="summaryTable.length > 0">
    <div style="overflow: auto;height: 400px;">
        <table id="exportTable" mat-table [dataSource]="datasource" style="width: 100%;">

            <ng-container matColumnDef="vesselName">
                <th mat-header-cell *matHeaderCellDef> Vessel Name </th>
                <td mat-cell *matCellDef="let element"> {{element.shipName}} </td>
            </ng-container>

            <ng-container matColumnDef="nauticalDriven">
                <th mat-header-cell *matHeaderCellDef> Nautical Mile </th>
                <td mat-cell *matCellDef="let element"> {{element.distance | number:'.1-2'}} </td>
            </ng-container>

            <ng-container matColumnDef="cumCO2">
                <th mat-header-cell *matHeaderCellDef>CO2 (%V/V)</th>
                <td mat-cell *matCellDef="let element"> {{element.crbnDiOx/element.count | number:'.1-2'}} </td>
            </ng-container>

            <ng-container matColumnDef="cumO2">
                <th mat-header-cell *matHeaderCellDef> O2 (%V/V)</th>
                <td mat-cell *matCellDef="let element"> {{element.oxi/element.count | number:'.1-2'}} </td>
            </ng-container>

            <ng-container matColumnDef="cumCO">
                <th mat-header-cell *matHeaderCellDef> CO (Avg. ppm)</th>
                <td mat-cell *matCellDef="let element"> {{element.crbnMoOx/element.count | number:'.1-2'}} </td>
            </ng-container>

            <ng-container matColumnDef="cumSOx">
                <th mat-header-cell *matHeaderCellDef> SOx (Avg. ppm)</th>
                <td mat-cell *matCellDef="let element"> {{element.sox/element.count | number:'.1-2'}} </td>
            </ng-container>

            <ng-container matColumnDef="cumNOx">
                <th mat-header-cell *matHeaderCellDef> NOx (Avg. ppm)</th>
                <td mat-cell *matCellDef="let element"> {{element.nitrOxi/element.count | number:'.1-2'}} </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedSummaryColumns" style="background: lightgrey;"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedSummaryColumns;"></tr>

        </table>
    </div>
</ng-container>
<div *ngIf="noonTable.length > 0" style="overflow: auto;height: 400px;">
    <div>
        <table id="exportTable" mat-table [dataSource]="datasource" style="width: 100%;">

            <ng-container matColumnDef="vname">
                <th mat-header-cell *matHeaderCellDef>Vessel Name</th>
                <td mat-cell *matCellDef="let element"> {{element.vesselName}} </td>
            </ng-container>

            <ng-container matColumnDef="imo">
                <th mat-header-cell *matHeaderCellDef>IMO Number</th>
                <td mat-cell *matCellDef="let element"> {{element.imoNumber}} </td>
            </ng-container>

            <ng-container matColumnDef="cp">
                <th mat-header-cell *matHeaderCellDef>Captain</th>
                <td mat-cell *matCellDef="let element"> {{element.captain}} </td>
            </ng-container>

            <ng-container matColumnDef="ce">
                <th mat-header-cell *matHeaderCellDef>Chief Engineer</th>
                <td mat-cell *matCellDef="let element"> {{element.chiefEng}} </td>
            </ng-container>

            <ng-container matColumnDef="rt">
                <th mat-header-cell *matHeaderCellDef>Report Type</th>
                <td mat-cell *matCellDef="let element"> {{element.reportType}} </td>
            </ng-container>

            <ng-container matColumnDef="rs">
                <th mat-header-cell *matHeaderCellDef>Report Status</th>
                <td mat-cell *matCellDef="let element"> {{element.reportStatus}} </td>
            </ng-container>

            <ng-container matColumnDef="CO2">
                <th mat-header-cell *matHeaderCellDef>CO2 (%V/V)</th>
                <td mat-cell *matCellDef="let element"> {{element.coTwo | number:'.1-2'}} </td>
            </ng-container>

            <ng-container matColumnDef="O2">
                <th mat-header-cell *matHeaderCellDef> O2 (%V/V)</th>
                <td mat-cell *matCellDef="let element"> {{element.otwo | number:'.1-2'}} </td>
            </ng-container>

            <ng-container matColumnDef="CO">
                <th mat-header-cell *matHeaderCellDef> CO (ppm)</th>
                <td mat-cell *matCellDef="let element"> {{element.co | number:'.1-2'}} </td>
            </ng-container>

            <ng-container matColumnDef="SOx">
                <th mat-header-cell *matHeaderCellDef> SOx (ppm)</th>
                <td mat-cell *matCellDef="let element"> {{element.sox | number:'.1-2'}} </td>
            </ng-container>

            <ng-container matColumnDef="NOx">
                <th mat-header-cell *matHeaderCellDef> NOx (ppm)</th>
                <td mat-cell *matCellDef="let element"> {{element.nox | number:'.1-2'}} </td>
            </ng-container>

            <ng-container>
                <th mat-header-cell *matHeaderCellDef> Report Nature</th>
                <td mat-cell *matCellDef="let element"> {{element.reportNature}} </td>
            </ng-container>
            
            <ng-container matColumnDef="dt">
                <th mat-header-cell *matHeaderCellDef> </th>
                <td mat-cell *matCellDef="let element" (click)="viewNoonReportData(element)" class="color-blue hand" > See Details </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedNoonColumns" style="background: lightgrey;"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedNoonColumns;"></tr>

        </table>
    </div>
</div>


<div *ngIf="emissionTable.length > 0">
    <div class="row">
        <div class="col-sm-6" style="overflow: auto;height: 400px;">
            <table id="exportTable" mat-table [dataSource]="datasource" style="width: 100%;">

                <ng-container matColumnDef="dt">
                    <th mat-header-cell *matHeaderCellDef>DATE & TIME <br/> (GMT)</th>
                    <td mat-cell *matCellDef="let element"> {{element.deviceTime | date:'MMM d, y HH:mm:ss':'UTC'}} </td>
                </ng-container>

                <ng-container matColumnDef="CO2">
                    <th mat-header-cell *matHeaderCellDef>CO2 <br/> (%V/V)</th>
                    <td mat-cell *matCellDef="let element"> {{element.crbnDiOx | number:'.1-2'}} </td>
                </ng-container>

                <ng-container matColumnDef="O2">
                    <th mat-header-cell *matHeaderCellDef> O2 <br/> (%V/V)</th>
                    <td mat-cell *matCellDef="let element"> {{element.oxi | number:'.1-2'}} </td>
                </ng-container>

                <ng-container matColumnDef="CO">
                    <th mat-header-cell *matHeaderCellDef> CO <br/> (ppm)</th>
                    <td mat-cell *matCellDef="let element"> {{element.crbnMoOx | number:'.1-2'}} </td>
                </ng-container>

                <ng-container matColumnDef="SOx">
                    <th mat-header-cell *matHeaderCellDef> SOx <br/> (ppm)</th>
                    <td mat-cell *matCellDef="let element"> {{element.sox | number:'.1-2'}} </td>
                </ng-container>

                <ng-container matColumnDef="NOx">
                    <th mat-header-cell *matHeaderCellDef> NOx <br/> (ppm)</th>
                    <td mat-cell *matCellDef="let element"> {{element.nitrOxi | number:'.1-2'}} </td>
                </ng-container> 
                
                <ng-container matColumnDef="speed">
                    <th mat-header-cell *matHeaderCellDef> Speed <br/> (NM)</th>
                    <td mat-cell *matCellDef="let element"> {{element.speed | number:'.0'}} </td>
                </ng-container> 

                <tr mat-header-row *matHeaderRowDef="displayedEmissionColumns" style="background: lightgrey;"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedEmissionColumns;"></tr>

            </table>
        </div>
        <div class="col-sm-6" style="overflow: auto;height: 400px;">
            <div class="row" style="margin-left: 1px; background-color: white;margin-top: 25px;">
                <ngx-charts-line-chart [view]="viewLineChart" [scheme]="colorScheme" [results]="lineChartEmissionData1"
                    [gradient]="gradient" [xAxis]="showXAxis" [yAxis]="showYAxis" [showXAxisLabel]="showXAxisLabel"
                    [referenceLines]="ReferenceLine" [showYAxisLabel]="showYAxisLabel" [xAxisLabel]="xAxisLabel"
                    [yAxisLabel]="yAxisLabel" [autoScale]="autoScale" [timeline]="false" [showRefLines]="false"
                    [showRefLabels]="false" [legend]="true" [legendTitle]="Emission" [legendPosition]="legendPosition">
                </ngx-charts-line-chart>                
            </div>
            <div class="row" style="margin-left: 1px; background-color: white;margin-top: 25px;">
                <ngx-charts-line-chart [view]="viewLineChart" [scheme]="colorScheme" [results]="lineChartEmissionData2"
                    [gradient]="gradient" [xAxis]="showXAxis" [yAxis]="showYAxis" [showXAxisLabel]="showXAxisLabel"
                    [referenceLines]="ReferenceLine" [showYAxisLabel]="showYAxisLabel" [xAxisLabel]="xAxisLabel"
                    [yAxisLabel]="yAxisLabel" [autoScale]="autoScale" [timeline]="false" [showRefLines]="false"
                    [showRefLabels]="false" [legend]="true" [legendTitle]="Emission" [legendPosition]="legendPosition">
                </ngx-charts-line-chart>
            </div>

        </div>
        <!-- <div class="row" style="margin-left: 1px; background-color: white;margin-top: 25px;">
        </div>
        <div class="row" style="margin-left: 1px; background-color: white;margin-top: 25px;">
        </div> -->
    </div>
</div>