import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AdminDetailService } from 'src/app/shared/admin-detail.service';

@Injectable({
    providedIn: 'root'
})
export class LoginService {
    constructor(private httpClient: HttpClient,
        private adminDetailService: AdminDetailService) { }

    userLogin(email: any, password: any) {
        let url = this.adminDetailService.API_URL_IOTA + "/authservice/v1/oauth/token";
        let body = `grant_type=password&client_id=${this.adminDetailService.CLIENT_ID}&client_secret=${this.adminDetailService.CLIENT_SECRET}&username=${email}&password=${password}`;
        return this.httpClient.post(url, body, {
            headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded'),
            observe: 'response'
        });
    }


    getUserContext(userId: any, access_token: any) {
        let options = { headers: new HttpHeaders().set('token', access_token) }
        let url = `${this.adminDetailService.API_URL_IOTA}/userdeviceservice/v1/user/context/${userId}`;
        return this.httpClient.get(url, options);
    }

    logout() {
        sessionStorage.removeItem('login');
        sessionStorage.removeItem('userDetail');
    }

}