<div class="row"
    style="padding: 10px 0px 0px 0px;margin-top: -20px;margin-left: -20px;margin-right: -20px;background-color: #021f10;">
    <div class="col-md-3">
        <mat-form-field class="example-full-width" appearance="fill">
            <mat-label>Vessel </mat-label>
            <mat-select [(value)]="vessel" (selectionChange)="changeDevice()">
                <mat-option *ngFor="let vessel of vesselList" [value]="vessel.shipId">
                    {{vessel.shipName}}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div class="col-md-3">
        <mat-form-field class="example-full-width" appearance="fill">
            <mat-label>Date </mat-label>
            <mat-select [(value)]="historyRange" (selectionChange)="reloadHistory()">
                <mat-option value="recent">
                    Recent
                </mat-option>
                <mat-option value="today">
                    Today
                </mat-option>
                <mat-option value="last24">
                    Last 24 Hours
                </mat-option>
                <mat-option value="last7">
                    Last 7 Days
                </mat-option>
                <mat-option value="voyage">
                    By Voyage
                </mat-option>
                <mat-option value="custom">
                    Custom Date
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div class="col-md-3" *ngIf="historyRange=='voyage'">
        <mat-form-field class="example-full-width" appearance="fill">
            <mat-label>Voyage </mat-label>
            <mat-select [(value)]="voyageSelected">
                <mat-option value="">
                    Select Voyage
                </mat-option>
                <mat-option *ngFor="let voyage of voyageList" [value]="voyage">
                    {{voyage.voyageNumber}}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div class="col-md-3" *ngIf="historyRange=='voyage'">
        <button mat-raised-button color="primary" (click)="voyageChangeApply()">Apply</button>
    </div>
    <div class="col-md-6" *ngIf="historyRange=='custom'">
        <form [formGroup]="range">
            <div class="row">
                <div class="col-md-5">
                    <mat-form-field class="example-full-width" appearance="fill">
                        <mat-label>From Date </mat-label>
                        <input matInput formControlName="fromDate" [max]="maxDate" [ngxMatDatetimePicker]="fromPicker">
                        <mat-datepicker-toggle matSuffix [for]="fromPicker"></mat-datepicker-toggle>
                        <ngx-mat-datetime-picker #fromPicker [showSeconds]="showSeconds" [stepHour]="stepHour"
                            [stepMinute]="stepMinute" [stepSecond]="stepSecond" [touchUi]="touchUi">
                        </ngx-mat-datetime-picker>
                    </mat-form-field>
                </div>
                <div class="col-md-5">
                    <mat-form-field class="example-full-width" appearance="fill">
                        <mat-label>To Date </mat-label>
                        <input matInput formControlName="toDate" [max]="maxDate" [ngxMatDatetimePicker]="toPicker">
                        <mat-datepicker-toggle matSuffix [for]="toPicker"></mat-datepicker-toggle>
                        <ngx-mat-datetime-picker #toPicker [showSeconds]="showSeconds" [stepHour]="stepHour"
                            [stepMinute]="stepMinute" [stepSecond]="stepSecond" [touchUi]="touchUi">
                        </ngx-mat-datetime-picker>
                    </mat-form-field>
                </div>
                <div class="col-md-2">
                    <button mat-raised-button color="primary" (click)="applyCustomDate()">Apply</button>
                </div>
            </div>
        </form>
    </div>
</div>

<div class="row" style="margin-right: -20px;margin-left: -17px;">
    <div class="col-sm-8 text-center text-middle" style="font-size: 14px;height: 82vh;"
        *ngIf="tripTimelineList?.length == 0">
        No trip found!
    </div>
    <div class="col-sm-5" style="padding-left:1px;padding-right: 1px;background-color: #ffffff;"
        *ngIf="tripTimelineList?.length>0">

        <div class="widget" style="margin-bottom: 0px;background-color: #f1f1f1!important;">
            <div class="widget-content" style="padding: 0px 2px 2px 2px;">
                <div class="multiview-content" style="height: 82vh;">
                    <div class="row margin-top bg-color-white" *ngIf="tripTimelineList?.length==0">
                        <div class="col-lg-12 col-md-12 text-center">
                            <span><b>You can see live graph when vehicle will move</b></span>
                        </div>
                    </div>
                    <ng-container *ngIf="tripTimelineList?.length>0">
                        <div class="tab" style="height: 38px;">
                            <div class="row">
                                <div class="col-md-4 text-right">
                                    <button style="float:none;" (click)="changeSpeed(false)">
                                        <mat-icon> fast_rewind</mat-icon>
                                    </button>
                                </div>
                                <div class="col-md-1 text-center"
                                    style="font-size: 14px;margin: auto; font-weight: 700;bottom: 3px;">
                                    {{playback_speed / 1000 |
                                    number:0}}X
                                </div>
                                <div class="col-md-4 text-left">
                                    <button (click)="changeSpeed(true)" style="float:none;">
                                        <mat-icon>fast_forward</mat-icon>
                                    </button>
                                </div>
                                <div class="col-md-2">
                                    <button class="tablinks" style="float: right;" (click)="pausePlayback()"
                                        *ngIf="!play.show">
                                        <mat-icon title="Pause">pause</mat-icon>
                                    </button>
                                </div>
                                <div class="col-md-1">
                                    <button class="tablinks" style="float: right;" (click)="stopPlayback()"
                                        *ngIf="!play.show">
                                        <mat-icon title="Stop">stop</mat-icon>
                                    </button>
                                    <button class="tablinks" style="float: right;" (click)="startPlayback()"
                                        *ngIf="play.show">
                                        <mat-icon title="Start">play_circle_outline</mat-icon>
                                    </button>
                                </div>
                            </div>
                        </div>

                        <div class="row margin-top">
                            <div class="col-lg-5 bg-color-white">
                                <google-chart style="margin: auto;" [title]="coChart.title" [type]="coChart.type"
                                    [data]="coChart.data" [options]="coChart.options">
                                </google-chart>
                            </div>
                            <div class="col-lg-7">
                                <div style="margin-left: 1px; background-color: white;">
                                    <ngx-charts-line-chart [view]="viewLineChart" [scheme]="coColorScheme"
                                        [results]="coLineChartEmissionData" [gradient]="gradient" [xAxis]="showXAxis"
                                        [yAxis]="showYAxis" [showXAxisLabel]="showXAxisLabel"
                                        [referenceLines]="coReferenceLine" [showYAxisLabel]="showYAxisLabel"
                                        [xAxisLabel]="xAxisLabel" [yAxisLabel]="yAxisCOLabel" [autoScale]="autoScale"
                                        [timeline]="false" [showRefLines]="true" [showRefLabels]="true">
                                    </ngx-charts-line-chart>
                                </div>
                            </div>
                        </div>
                        <div class="row margin-top">
                            <div class="col-lg-5 bg-color-white">
                                <google-chart style="margin: auto;" [title]="noxChart.title" [type]="noxChart.type"
                                    [data]="noxChart.data" [options]="noxChart.options">
                                </google-chart>
                            </div>
                            <div class="col-lg-7">
                                <div style="margin-left: 1px; background-color: white;">
                                    <ngx-charts-line-chart [view]="viewLineChart" [scheme]="noxColorScheme"
                                        [results]="noxLineChartEmissionData" [gradient]="gradient" [xAxis]="showXAxis"
                                        [yAxis]="showYAxis" [showXAxisLabel]="showXAxisLabel"
                                        [showYAxisLabel]="showYAxisLabel" [xAxisLabel]="xAxisLabel"
                                        [yAxisLabel]="yAxisNOxLabel" [autoScale]="autoScale" [timeline]="false"
                                        [showRefLines]="true" [showRefLabels]="true"
                                        [referenceLines]="noxReferenceLine">
                                    </ngx-charts-line-chart>
                                </div>
                            </div>
                        </div>
                        <div class="row margin-top">
                            <div class="col-lg-5 bg-color-white">
                                <google-chart style="margin: auto;" [title]="soxChart.title" [type]="soxChart.type"
                                    [data]="soxChart.data" [options]="soxChart.options">
                                </google-chart>
                            </div>
                            <div class="col-lg-7">
                                <div style="margin-left: 1px; background-color: white;">
                                    <ngx-charts-line-chart [view]="viewLineChart" [scheme]="soxColorScheme"
                                        [results]="soxLineChartEmissionData" [gradient]="gradient" [xAxis]="showXAxis"
                                        [yAxis]="showYAxis" [showXAxisLabel]="showXAxisLabel"
                                        [showYAxisLabel]="showYAxisLabel" [xAxisLabel]="xAxisLabel"
                                        [yAxisLabel]="yAxisSOxLabel" [autoScale]="autoScale" [timeline]="false"
                                        [showRefLines]="true" [showRefLabels]="true"
                                        [referenceLines]="soxReferenceLine">
                                    </ngx-charts-line-chart>
                                </div>
                            </div>
                        </div>
                        <div class="row margin-top">
                            <div class="col-lg-5 bg-color-white">
                                <google-chart style="margin: auto;" [title]="o2Chart.title" [type]="o2Chart.type"
                                    [data]="o2Chart.data" [options]="o2Chart.options">
                                </google-chart>
                            </div>
                            <div class="col-lg-7">
                                <div style="margin-left: 1px; background-color: white;">
                                    <ngx-charts-line-chart [view]="viewLineChart" [scheme]="o2ColorScheme"
                                        [results]="o2LineChartEmissionData" [gradient]="gradient" [xAxis]="showXAxis"
                                        [yAxis]="showYAxis" [showXAxisLabel]="showXAxisLabel"
                                        [showYAxisLabel]="showYAxisLabel" [xAxisLabel]="xAxisLabel"
                                        [yAxisLabel]="yAxisO2Label" [autoScale]="autoScale" [timeline]="false"
                                        [showRefLines]="true" [showRefLabels]="true" [referenceLines]="o2ReferenceLine">
                                    </ngx-charts-line-chart>
                                </div>
                            </div>
                        </div>
                        <div class="row margin-top">
                            <div class="col-lg-5 bg-color-white">
                                <google-chart style="margin: auto;" [title]="co2Chart.title" [type]="co2Chart.type"
                                    [data]="co2Chart.data" [options]="co2Chart.options">
                                </google-chart>
                            </div>
                            <div class="col-lg-7">
                                <div style="margin-left: 1px; background-color: white;">
                                    <ngx-charts-line-chart [view]="viewLineChart" [scheme]="co2ColorScheme"
                                        [results]="co2LineChartEmissionData" [gradient]="gradient" [xAxis]="showXAxis"
                                        [yAxis]="showYAxis" [referenceLines]="co2ReferenceLine"
                                        [showXAxisLabel]="showXAxisLabel" [showYAxisLabel]="showYAxisLabel"
                                        [xAxisLabel]="xAxisLabel" [yAxisLabel]="yAxisCO2Label" [autoScale]="autoScale"
                                        [timeline]="false" [showRefLines]="true" [showRefLabels]="true">
                                    </ngx-charts-line-chart>
                                </div>
                            </div>
                        </div>
                        <div class="row margin-top">
                            <div class="col-lg-5 bg-color-white">
                                <google-chart style="margin: auto;" [title]="speedChart.title" [type]="speedChart.type"
                                    [data]="speedChart.data" [options]="speedChart.options">
                                </google-chart>
                            </div>
                            <div class="col-lg-7">
                                <div style="margin-left: 1px;background-color: white;">
                                    <ngx-charts-line-chart [view]="viewLineChart" [scheme]="speedColorScheme"
                                        [results]="speedLineChartData" [gradient]="gradient" [xAxis]="showXAxis"
                                        [yAxis]="showYAxis" [showXAxisLabel]="showXAxisLabel"
                                        [showYAxisLabel]="showYAxisLabel" [xAxisLabel]="xAxisLabel"
                                        [yAxisLabel]="yAxisSpeedLabel" [autoScale]="autoScale" [timeline]="false">
                                    </ngx-charts-line-chart>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
    <div class="col-sm-3" *ngIf="tripTimelineList?.length>0"
        style="height: 82vh;overflow-x: hidden; overflow-y: hidden; padding-left: 1px; padding-right: 1px;background-color: #f1f1f1">
        <div class="vehicle-card">
            <div class="widget" style="margin-bottom: 10px;">
                <div class="widget-content p border-top clearfix" style="margin-bottom: 10px;">
                    <div class="row m-lr-ve">
                        <div class="col-sm-4" style="font-size: 12px;">
                            Start Location:
                        </div>

                        <div class="col-sm-8" style="font-size: 12px;">
                            <b>{{startLocation}}</b>
                        </div>
                    </div>
                    <div class="row m-lr-ve">
                        <div class="col-sm-4" style="font-size: 12px;">
                            End Location:
                        </div>

                        <div class="col-sm-8" style="font-size: 12px;">
                            <b>{{endLocation}}</b>
                        </div>
                    </div>
                    <div class="row m-lr-ve">
                        <div class="col-sm-4" style="font-size: 12px;">
                            Start Time:
                        </div>
                        <div class="col-sm-8" style="font-size: 12px;">
                            <b>{{startTime | date:'medium' }}</b>
                        </div>
                    </div>
                    <div class="row m-lr-ve">
                        <div class="col-sm-4" style="font-size: 12px;">
                            End Time:
                        </div>
                        <div class="col-sm-8" style="font-size: 12px;">
                            <b>{{endTime | date:'medium'}}</b>
                        </div>
                    </div>
                    <div class="row m-lr-ve">
                        <div class="col-sm-4" style="font-size: 12px;">
                            Duration:
                        </div>
                        <div class="col-sm-8" style="font-size: 12px;">
                            <b>{{durationOfTrip}} Hrs</b>
                        </div>
                    </div>

                </div>
                <div class="widget-content p border-top clearfix">
                    <div class="row m-lr-ve text-center">
                        <div class="col-sm-4 push-inner-top-bottom border-right" style="padding: 0px;">
                            <span>NOx<br />(Avg. ppm)</span>
                            <br /><b><span *ngIf="!cumNOx">0</span>
                                <span *ngIf="cumNOx">{{cumNOx | number:'.1-2'}}</span></b>
                        </div>
                        <div class="col-sm-4 push-inner-top-bottom border-right" style="padding: 0px;">
                            <span>SOx<br />(Avg. ppm)</span>
                            <br /><b> <span *ngIf="!cumSOx">0</span>
                                <span *ngIf="cumSOx">{{cumSOx | number:'.1-2'}}</span></b>
                        </div>

                        <div class="col-sm-4 push-inner-top-bottom border-right" style="padding: 0px;">
                            <span>CO<br />(Avg. ppm)</span><br />
                            <b><span *ngIf="!cumCO">0</span>
                                <span *ngIf="cumCO">{{cumCO | number:'.1-2'}} </span></b>
                        </div>


                    </div>
                </div>
                <div class="widget-content p border-top clearfix">
                    <div class="row m-lr-ve text-center">
                        <div class="col-sm-6 push-inner-top-bottom border-right" style="padding: 0px;">
                            <span>CO2<br />(%V/V)</span>
                            <br /> <b><span *ngIf="!cumCO2">0</span>
                                <span *ngIf="cumCO2">{{cumCO2 | number:'.1-2'}} </span></b>
                        </div>
                        <div class="col-sm-6 push-inner-top-bottom border-right" style="padding: 0px;">
                            <span>O2<br />(%V/V)</span>
                            <br /><b><span *ngIf="!cumO2">0</span>
                                <span *ngIf="cumO2">{{cumO2 | number:'.1-2'}}</span></b>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row margin-top bg-color-white">
                <div class="col-sm-12">
                    <h5 class="text-center" style="font-size: 14px;margin-top: 10px;  font-weight: bold;">
                        Alerts</h5>
                    <div class="row">
                        <div class="col-lg-12 col-md-12" style="overflow-y: auto;height: 43vh;">
                            <div class="widget-content p border-top clearfix" *ngFor="let alert of alertList">
                                <span style="font-size: 12px;">{{alert.message}}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-sm-4" style="padding-left: 1px;padding-right: 1px;">
        <div #mapContainer id="map" style="height: 100%; width: 100%;"></div>
    </div>
</div>