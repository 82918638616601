/// <reference types="@types/googlemaps" />
import { DatePipe } from '@angular/common';
import { Component, AfterViewInit, ElementRef, NgZone, OnInit, ViewChild } from '@angular/core';
// import { } from 'googlemaps';
import { AdminDetailService } from 'src/app/shared/admin-detail.service';
import { DashboardService } from './dashboard.service';
import { Paho } from 'ng2-mqtt/mqttws31';
import { GoogleChartComponent } from 'angular-google-charts';


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit, AfterViewInit {

  constructor(private dashboardService: DashboardService,
    public adminDetailService: AdminDetailService,
    private datePipe: DatePipe,
    private ngZone: NgZone) {
    if (this.adminDetailService.status == "ONLINE") {
      this.connectToBroker();
    }
    this.adminDetailService.title = "Dashboard";
  }
  private _client!: Paho.MQTT.Client;
  @ViewChild('googlechart')
  googlechart!: GoogleChartComponent;

  @ViewChild('mapContainer', { static: false })
  gmap!: ElementRef;
  map!: google.maps.Map;
  lat = 22.55;
  lng = 88.333333;
  paths: any = [];
  polyline: any = [];
  connectionSuccessful = false;

  coordinates = new google.maps.LatLng(this.lat, this.lng);
  alertList: any = [];
  count_Value: number = 0;
  mapOptions: google.maps.MapOptions = {
    center: this.coordinates,
    zoom: 8,
    mapTypeControl: false,
    streetViewControl: false,
  };

  marker = new google.maps.Marker;
  ngOnInit(): void {
    // this.getDeviceList();
    if (this.adminDetailService.status == "OFFLINE") {
      this.deviceListOffline();
    }
  }
  ngAfterViewInit() {
    this.mapInitializer();
  }

  mapInitializer() {
    this.map = new google.maps.Map(this.gmap.nativeElement,
      this.mapOptions);

  }
  deviceLiveDataList: any = [];
  // message!: string;
  deviceList: any = [];
  tabView = 'live';
  selectedDevice: any = { isGraphData: false };

  filteredList: any = [];
  movingCar = 0;
  parkedCar = 0;
  idleCar = 0;
  inActiveCar = 0;
  cumCO2 = 0;
  cumCO = 0;
  cumO2 = 0;
  cumNOx = 0;
  cumSOx = 0;
  //chart options strt
  co2LineChartEmissionData = [
    {
      "name": 'CO2(%V/V)',
      "series": [] as any
    }
  ];
  coLineChartEmissionData = [
    {
      "name": 'CO(ppm)',
      "series": [] as any
    }
  ];
  soxLineChartEmissionData = [
    {
      "name": 'SOx(ppm)',
      "series": [] as any
    }
  ];
  noxLineChartEmissionData = [
    {
      "name": 'NOx(ppm)',
      "series": [] as any
    }
  ];
  o2LineChartEmissionData = [
    {
      "name": 'O2(%V/V)',
      "series": [] as any
    }
  ];

  speedLineChartData = [
    {
      "name": "Speed",
      "series": [] as any
    }
  ];

  viewLineChart: any[] = [340, 180];

  co2Chart = {
    type: 'Gauge',
    data: [
      ['CO2', 0]
    ],
    options: {
      width: 250,
      height: 180,
      greenFrom: 0,
      greenTo: 8,
      redFrom: 16,
      redTo: 25,
      yellowFrom: 8,
      yellowTo: 16,
      minorTicks: 5,
      max: 25,
      majorTicks: [0, 5, 10, 15, 20, 25]
    }
  };
  coChart = {
    type: 'Gauge',
    data: [
      ['CO', 0]
    ],
    options: {
      width: 280,
      height: 180,
      greenFrom: 0,
      greenTo: 667,
      redFrom: 1333,
      redTo: 2000,
      yellowFrom: 667,
      yellowTo: 1333,
      minorTicks: 5,
      max: 2000,
      majorTicks: [0, 400, 800, 1200, 1600, 2000]
    }
  };
  noxChart = {
    type: 'Gauge',
    data: [
      ['NOx', 0]
    ],
    options: {
      width: 280,
      height: 180,
      greenFrom: 0,
      greenTo: 667,
      redFrom: 1333,
      redTo: 2000,
      yellowFrom: 667,
      yellowTo: 1333,
      minorTicks: 5,
      max: 2000,
      majorTicks: [0, 400, 800, 1200, 1600, 2000]
    }
  };
  soxChart = {
    type: 'Gauge',
    data: [
      ['SOx', 0]
    ],
    options: {
      width: 280,
      height: 180,
      greenFrom: 0,
      greenTo: 667,
      redFrom: 1333,
      redTo: 2000,
      yellowFrom: 667,
      yellowTo: 1333,
      minorTicks: 5,
      max: 2000,
      majorTicks: [0, 400, 800, 1200, 1600, 2000]
    }
  };
  o2Chart = {
    type: 'Gauge',
    data: [
      ['O2', 0]
    ],
    options: {
      width: 280,
      height: 180,
      greenFrom: 0,
      greenTo: 8,
      redFrom: 16,
      redTo: 25,
      yellowFrom: 8,
      yellowTo: 16,
      minorTicks: 5,
      max: 25,
      majorTicks: [0, 5, 10, 15, 20, 25]
    }
  };
  speedChart = {
    type: 'Gauge',
    data: [
      ['Speed', 0]
    ],
    options: {
      width: 280,
      height: 180,
      greenFrom: 0,
      greenTo: 15,
      redFrom: 24,
      redTo: 40,
      yellowFrom: 15,
      yellowTo: 24,
      minorTicks: 5,
      max: 40,
      majorTicks: [0, 6, 16, 24, 32, 40]
    }
  };

  showXAxis = false;
  showYAxis = true;
  gradient = false;
  showLegend = false;
  showXAxisLabel = true;
  xAxisLabel = 'Time';
  showYAxisLabel = true;
  yAxisCO2Label = 'CO2(% V/V)';
  yAxisNOxLabel = "NOx(ppm)";
  yAxisSOxLabel = "SOx(ppm)";
  yAxisCOLabel = 'CO(ppm)';
  yAxisO2Label = "O2(% V/V)";
  yAxisSpeedLabel = 'Speed';
  co2ReferenceLine = [{ name: 'Maximum', value: 16 }];
  coReferenceLine = [{ name: 'Maximum', value: 1400 }]
  noxReferenceLine = [{ name: 'Maximum', value: 1400 }]
  soxReferenceLine = [{ name: 'Maximum', value: 1400 }]
  o2ReferenceLine = [{ name: 'Maximum', value: 16 }]
  co2ColorScheme = {
    domain: ['#5AA454']
  };
  coColorScheme = {
    domain: ['#A10A28']
  }
  noxColorScheme = {
    domain: ['#C7B42C']
  }
  soxColorScheme = {
    domain: ['#AAAAAA']
  }
  o2ColorScheme = {
    domain: ['#FFA500']
  }
  speedColorScheme = {
    domain: ['#000080']
  }
  autoScale = false;
  //chart option end

  getDeviceList() {

    this.dashboardService.getVesselList().subscribe(
      (res: any) => {
        if (res) {
          this.deviceList = res.data;
          this.adminDetailService.deviceList = this.deviceList;
          this.filteredList = this.deviceList;
          this.selectedDevice = this.deviceList[0];
          this.selectedDevice.selected = true;

          for (var i = 0; i < this.deviceList.length; i++) {
            var entity = this.deviceList[i];
            this.subscribeToTopic(entity.deviceId);
            entity.isMoving = false;
            entity.isIdle = false;
            entity.status = "offline";
            entity.movingText = "Now Parked";
            entity.cumCO2 = 0;
            entity.cumCO = 0;
            entity.cumNOx = 0;
            entity.cumSOx = 0;
            entity.cumO2 = 0;
            this.lat = entity.lastLat;
            this.lng = entity.lastLng;
            this.parkedCar++;
            entity.emissionData = {};
            entity.i = i;
            if (entity.lastUpdate) {
              const lastUpdate = this.datePipe.transform(entity.lastUpdate, 'yyyy-MM-dd HH:mm:ss', this.adminDetailService.offset);
              var diff = new Date().getTime() - new Date(lastUpdate || '').getTime();
              if (diff > 28800000) {
                this.parkedCar--;
                this.inActiveCar++;
                entity.movingText = "In-active";
              }
            } else {
              this.parkedCar--;
              this.inActiveCar++;
              entity.movingText = "In-active";
            }
          }

          // this.getLiveData();
        }
      }, (err) => {
        alert("Data don't get successfully: " + err.error.message);
      }

    )
  }

  deviceListOffline() {
    this.deviceList = this.adminDetailService.deviceList;
    this.filteredList = this.deviceList;
    this.selectedDevice = this.deviceList[0];
    this.selectedDevice.selected = true;

    for (var i = 0; i < this.deviceList.length; i++) {
      var entity = this.deviceList[i];
      entity.isMoving = false;
      entity.isIdle = false;
      entity.status = "offline";
      entity.movingText = "Now Parked";
      entity.cumCO2 = 0;
      entity.cumCO = 0;
      entity.cumNOx = 0;
      entity.cumSOx = 0;
      entity.cumO2 = 0;
      this.lat = entity.lastLat;
      this.lng = entity.lastLng;
      this.parkedCar++;
      entity.emissionData = {};
      entity.i = i;
      if (entity.lastUpdate) {
        const lastUpdate = this.datePipe.transform(entity.lastUpdate, 'medium', this.adminDetailService.offset);
        var diff = new Date().getTime() - new Date(lastUpdate || '').getTime();
        if (diff > 28800000) {
          this.parkedCar--;
          this.inActiveCar++;
          entity.movingText = "In-active";
        }
      } else {
        this.parkedCar--;
        this.inActiveCar++;
        entity.movingText = "In-active";
      }
    }
  }

  connectToBroker() {
    console.log("connect to broker function");
    this._client = new Paho.MQTT.Client(this.adminDetailService.MQTT_URL, 61614, "clientId-" + Math.floor(Math.random() * 6000) + 1);

    this._client.onConnectionLost = (responseObject: Object) => {
      console.log('Connection lost.');
    };

    this._client.onMessageArrived = (message: Paho.MQTT.Message) => {
      let object = JSON.parse(message.payloadString);
      this.updateLiveData(object);
    };
    this._client.connect({ onSuccess: this.onConnected.bind(this), userName: "consumer", password: "iGreen@7890" });

  }

  private onConnected(): void {
    console.log('Connected to broker.');
    this.connectionSuccessful = true;
    this.getDeviceList();
    // this.subscribeToTopic('emission');
  }
  private publishMessage(topicName: string, msg: string) {
    let message = new Paho.MQTT.Message(msg);
    message.destinationName = topicName;
    this._client.send(message);
  }

  private subscribeToTopic(topicName: string) {
    this._client.subscribe(topicName, {});
  }

  ngOnDestroy() {
    console.log("disconnected");
    if (this.adminDetailService.status == "ONLINE" && this.connectionSuccessful == true) {
      this._client.disconnect();
    }
  }

  markerIcon = {
    path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
    scale: 7,
    strokeColor: '#9c9c9c',
    strokeWeight: .10,
    fillOpacity: 1,
    fillColor: '#0D3340',
    rotation: 0,
    anchor: new google.maps.Point(0, 2)
  };

  getDeviceById(id: any) {
    let device = null;
    for (var i = 0; i < this.deviceList.length; i++) {
      if (id == this.deviceList[i].deviceId) {
        device = this.deviceList[i];
        break;
      }

    }
    return device;
  }
  oldLat = 0;
  oldLng = 0;

  updateLiveData(deviceData: any) {
    let device = this.getDeviceById(deviceData.deviceId);
    if (device === null) {
      return;
    }

    var devData = deviceData;

    this.ngZone.run(() => {
      if (!device.startAt) {
        device.startAt = this.datePipe.transform(devData.ts * 1000, 'medium', this.adminDetailService.offset);
      }
      device.emissionData = devData;
      device.lastUpdate = this.datePipe.transform(devData.ts * 1000, 'medium', this.adminDetailService.offset);
      console.log(devData);
      device.lastAddress = devData.lat + "," + devData.lng;
      device.status = devData.status;
      device.speed = devData.speed;
      this.cumCO2 += devData.crbnDiOx;
      this.cumCO += devData.crbnMoOx;
      this.cumNOx += devData.nitrOxi;
      this.cumSOx += devData.sox;
      this.cumO2 += devData.oxi;
      this.count_Value += 1;
      if (this.count_Value > 0) {
        device.cumCO2 = this.cumCO2 / this.count_Value;
        device.cumCO = this.cumCO / this.count_Value;
        device.cumNOx = this.cumNOx / this.count_Value;
        device.cumSOx = this.cumSOx / this.count_Value;
        device.cumO2 = this.cumO2 / this.count_Value;
      } else {
        device.cumCO2 = 0;
        device.cumCO = 0;
        device.cumNOx = 0;
        device.cumSOx = 0;
        device.cumO2 = 0;
      }
      if (device.movingText == 'In-active') {
        this.inActiveCar--;
      } else if (device.movingText == 'Now Parked') {
        // this.parkedCar--;
      } else if (device.movingText == 'Idle') {
        this.idleCar--;
      } else {
        this.movingCar--;
      }
      if (devData.status == 'online' && devData.speed > 0) {
        device.movingText = "Moving...";
        this.movingCar++;
        device.isMoving = true;
        device.isIdle = false;
      } else if (devData.status == 'online' && devData.speed < 1) {
        device.movingText = "Idle";
        this.idleCar++;
        device.isIdle = true;
        device.isMoving = false;
      } else {
        device.movingText = "Now Parked";
      }

      if (this.selectedDevice && this.selectedDevice.deviceId == device.deviceId) {
        if (devData.type == '4007') {
          let alarmMessage = "";
          if (devData.attributes.alarmType == "NOX") {
            alarmMessage = `NOx value ${devData.attributes.currentValue} ppm is above threshold of ${devData.attributes.threshold} ppm. Detected at ${devData.lat} , ${devData.lng}  on  ${this.datePipe.transform(new Date(devData.ts * 1000), 'medium')}`;
          }
          else if (devData.attributes.alarmType == "CO2") {
            alarmMessage = `CO2 value ${devData.attributes.currentValue} ppm is above threshold of ${devData.attributes.threshold} ppm. Detected at ${devData.lat} , ${devData.lng}  on  ${this.datePipe.transform(new Date(devData.ts * 1000), 'medium')}`;
          }
          else if (devData.attributes.alarmType == "CO") {
            alarmMessage = `CO value ${devData.attributes.currentValue} ppm is above threshold of ${devData.attributes.threshold} ppm. Detected at ${devData.lat} , ${devData.lng}  on  ${this.datePipe.transform(new Date(devData.ts * 1000), 'medium')}`;
          }
          else if (devData.attributes.alarmType == "SOX") {
            alarmMessage = `SOx value ${devData.attributes.currentValue} ppm is above threshold of ${devData.attributes.threshold} ppm. Detected at ${devData.lat} , ${devData.lng}  on  ${this.datePipe.transform(new Date(devData.ts * 1000), 'medium')}`;
          }
          else if (devData.attributes.alarmType == "O2") {
            alarmMessage = `O2 value ${devData.attributes.currentValue} ppm is above threshold of ${devData.attributes.threshold} ppm. Detected at ${devData.lat} , ${devData.lng}  on  ${this.datePipe.transform(new Date(devData.ts * 1000), 'medium')}`;
          }

          this.alertList.unshift({ message: alarmMessage });
        }
        let values = devData;
        let path = { lat: devData.lat, lng: devData.lng };
        this.paths.push(path);
        this.createPolyline();
        this.coordinates = new google.maps.LatLng(devData.lat, devData.lng);
        this.map.setCenter(this.coordinates);
        this.marker.setMap(null);
        if (this.oldLat != values.lat && this.oldLng != values.lng) {
          var oldPosition = new google.maps.LatLng(this.oldLat, this.oldLng);
          this.oldLat = values.lat;
          this.oldLng = values.lng;
          let newPosition = new google.maps.LatLng(values.lat, values.lng);
          let heading = google.maps.geometry.spherical.computeHeading(oldPosition, newPosition);
          this.markerIcon.rotation = heading;
          this.marker = new google.maps.Marker({
            position: this.coordinates,
            map: this.map,
            icon: this.markerIcon,
            draggable: true,
            visible: true,
          });

        }
        this.marker.setMap(this.map);
        if (this.co2LineChartEmissionData[0].series.length > 50) {
          this.co2LineChartEmissionData[0].series.shift()
        }
        if (this.coLineChartEmissionData[0].series.length > 50) {
          this.coLineChartEmissionData[0].series.shift()
        }
        if (this.noxLineChartEmissionData[0].series.length > 50) {
          this.noxLineChartEmissionData[0].series.shift()
        }
        if (this.soxLineChartEmissionData[0].series.length > 50) {
          this.soxLineChartEmissionData[0].series.shift()
        }
        if (this.o2LineChartEmissionData[0].series.length > 50) {
          this.o2LineChartEmissionData[0].series.shift()
        }
        if (this.speedLineChartData[0].series.length > 50) {
          this.speedLineChartData[0].series.shift()
        }

        this.co2LineChartEmissionData[0].series.push({ "name": new Date, "value": devData.crbnDiOx });
        this.coLineChartEmissionData[0].series.push({ "name": new Date, "value": devData.crbnMoOx });
        this.noxLineChartEmissionData[0].series.push({ "name": new Date, "value": devData.nitrOxi });
        this.soxLineChartEmissionData[0].series.push({ "name": new Date, "value": devData.sox });
        this.o2LineChartEmissionData[0].series.push({ "name": new Date, "value": devData.oxi });
        this.speedLineChartData[0].series.push({ "name": new Date, "value": devData.speed });

        this.co2Chart.data = [['CO2', devData.crbnDiOx]];
        this.coChart.data = [['CO', devData.crbnMoOx]];
        this.noxChart.data = [['NOx', devData.nitrOxi]];
        this.soxChart.data = [['SOx', devData.sox]];
        this.o2Chart.data = [['O2', devData.oxi]];
        this.speedChart.data = [['Speed', devData.speed]];

        this.co2LineChartEmissionData = Object.assign([devData.crbnDiOx], this.co2LineChartEmissionData);
        this.coLineChartEmissionData = Object.assign([devData.crbnMoOx], this.coLineChartEmissionData);
        this.noxLineChartEmissionData = Object.assign([devData.nitrOxi], this.noxLineChartEmissionData);
        this.soxLineChartEmissionData = Object.assign([devData.sox], this.soxLineChartEmissionData);
        this.o2LineChartEmissionData = Object.assign([devData.oxi], this.o2LineChartEmissionData);
        this.speedLineChartData = Object.assign([devData.speed], this.speedLineChartData);
        this.selectedDevice.isGraphData = true;
      }
    })

  }

  createPolyline() {
    if (this.paths.length > 1) {
      for (let l = 0; l < this.paths.length - 1; l++) {
        let polyCoordinates = [];
        polyCoordinates.push(new google.maps.LatLng(this.paths[l]['lat'], this.paths[l]['lng']));
        polyCoordinates.push(new google.maps.LatLng(this.paths[l + 1]['lat'], this.paths[l + 1]['lng']));
        let polyLine = new google.maps.Polyline({
          path: polyCoordinates,
          strokeOpacity: 1,
          strokeWeight: 3,
          geodesic: true,
          strokeColor: 'blue'
        });
        this.polyline.push(polyLine);
      }
      for (let k = 0; k < this.polyline.length; k++) {
        this.polyline[k].setMap(this.map);
      }
    }

  }

  changeDevice(device: any) {
    if (this.selectedDevice) {
      if (this.selectedDevice.shipId == device.shipId) {
        return;
      }
    }

    if (this.selectedDevice) {
      this.selectedDevice.selected = false;
    }
    this.selectedDevice = device;
    this.selectedDevice.selected = true;
    for (let k = 0; k < this.polyline.length; k++) {
      this.polyline[k].setMap(null);
    }
    this.marker.setMap(null);
    // this.deviceLiveDataList = [];
    this.alertList = [];
    this.resetGraph();
    this.selectedDevice.isGraphData = false;
    // this.getLiveData();
  }

  resetGraph() {
    this.co2Chart.data = [
      ['CO2', 0]
    ],
      this.coChart.data = [
        ['CO', 0]
      ]
    this.noxChart.data = [
      ['NOx', 0]
    ]

    this.soxChart.data = [
      ['SOx', 0]
    ]
    this.o2Chart.data = [
      ['O2', 0]
    ]
    this.speedChart.data = [
      ['Speed', 0]
    ]

    this.co2LineChartEmissionData = [
      {
        "name": 'CO2(%V/V)',
        "series": [] as any
      }
    ];
    this.coLineChartEmissionData = [
      {
        "name": 'CO(ppm)',
        "series": [] as any
      }
    ];
    this.soxLineChartEmissionData = [
      {
        "name": 'SOx(ppm)',
        "series": [] as any
      }
    ];
    this.noxLineChartEmissionData = [
      {
        "name": 'NOx(ppm)',
        "series": [] as any
      }
    ];
    this.o2LineChartEmissionData = [
      {
        "name": 'O2(%V/V)',
        "series": [] as any
      }
    ];

    this.speedLineChartData = [
      {
        "name": "Speed",
        "series": [] as any
      }
    ];
  }

  toggleMiddleWindow(view: any) {
    this.tabView = view;
  }

  filter(query: any) {
    this.filteredList = this.deviceList.filter((item: any) => {
      return item.movingText.includes(query);

    })
  }
}
