<div class="fleet-bar-div" style="padding-top: 5px;">
    <form [formGroup]="dateRange">
        <div class="row">
            <div class="col-sm-2">
                <mat-form-field class="example-full-width" appearance="fill">
                    <mat-label>Date and Time: </mat-label>
                    <mat-select [(value)]="dateType" (selectionChange)="reloadHistory()">
                        <mat-option value="today">
                            Today
                        </mat-option>
                        <mat-option value="last24">
                            Last 24 Hours
                        </mat-option>
                        <mat-option value="last7">
                            Last 7 Days
                        </mat-option>
                        <mat-option value="custom">
                            Custom Date
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-sm-2">
                <mat-form-field class="example-full-width" appearance="fill">
                    <mat-label>Vessel </mat-label>
                    <mat-select [(value)]="vesselSelected" (selectionChange)="changeDevice()">
                        <mat-option *ngFor="let vessel of vesselList" [value]="vessel.shipId">
                            {{vessel.shipName}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-sm-2">
                <button mat-raised-button color="primary" [disabled]="excelButtonDisabled"
                    (click)="exportExcel()">Export Excel</button>
            </div>
            <div class="col-sm-2">
                <button mat-raised-button color="primary" [disabled]="pdfButtonDisabled" (click)="exportPdf()">Export
                    Pdf</button>
            </div>
            <div class="col-sm-2"></div>
            <div class="col-sm-2">
                <button mat-raised-button color="primary" (click)="openAssignmentForm()">Add
                    Voyage</button>
            </div>
        </div>
        <div class="row" *ngIf="dateType =='custom'">
            <div class="col-sm-2">
                <mat-form-field class="example-full-width" appearance="fill">
                    <mat-label>From Date </mat-label>
                    <input matInput formControlName="fromDate" [ngxMatDatetimePicker]="fromPicker">
                    <mat-datepicker-toggle matSuffix [for]="fromPicker"></mat-datepicker-toggle>
                    <ngx-mat-datetime-picker #fromPicker [showSeconds]="showSeconds" [stepHour]="stepHour"
                        [stepMinute]="stepMinute" [stepSecond]="stepSecond" [touchUi]="touchUi">
                    </ngx-mat-datetime-picker>
                </mat-form-field>
            </div>
            <div class="col-sm-2">
                <mat-form-field class="example-full-width" appearance="fill">
                    <mat-label>To Date </mat-label>
                    <input matInput formControlName="toDate" [ngxMatDatetimePicker]="toPicker">
                    <mat-datepicker-toggle matSuffix [for]="toPicker"></mat-datepicker-toggle>
                    <ngx-mat-datetime-picker #toPicker [showSeconds]="showSeconds" [stepHour]="stepHour"
                        [stepMinute]="stepMinute" [stepSecond]="stepSecond" [touchUi]="touchUi">
                    </ngx-mat-datetime-picker>
                </mat-form-field>
            </div>
            <div class="col-sm-2">
                <button mat-raised-button color="primary" (click)="applyDate()">Apply</button>
            </div>
        </div>
    </form>
</div>
<div class="row" style="margin-top: 10px;">
    <div class="col-lg-12">
        <!-- <mat-form-field style="background-color: white;padding-left: 5px ; padding-top: 5px; width: 100%;">
                    <mat-label>Search</mat-label>
                    <input matInput (keyup)="applyFilter($event.target.value)" placeholder="ex- simId">
                  </mat-form-field> -->
        <div *ngIf="assignmentList.length==0" class="text-center" style="font-size: 15px;">Voyage Not Found!</div>
        <div class="example-table-container" id="exportPdf" style="height: 500px;overflow-y: auto;"
            *ngIf="assignmentList.length>0">
            <mat-card style="margin-top: 10px;" *ngFor="let voyage of assignmentList">
                <mat-card-title>
                    <div class="row">
                        <div class="col-md-3" style="font-size: 16px; font-weight: 600;padding-top: 8px;">
                            Voyage No : {{voyage.voyageNumber}}
                        </div>
                        <div class="col-md-3" style="font-size: 14px;padding-top: 10px;">
                            Vessel Name : {{voyage.shipName}}
                        </div>
                        <div class="col-md-2">
                            <button mat-stroked-button color="primary" *ngIf="voyage.bunkeringAt == null"
                                (click)="openUpdateAssignmentForm(voyage)">Edit</button>
                        </div>
                        <div class="col-md-2">
                            <button mat-stroked-button *ngIf="voyage.bunkeringAt == null" color="primary"
                                (click)="openBunkeringForm(voyage.id)">Add
                                Bunkering</button>
                        </div>
                        <div class="col-md-2">
                            <button mat-stroked-button color="primary" *ngIf="voyage.redeliveryAt == null"
                                (click)="openRedeliveryForm(voyage.id)">Add
                                Re-Delivery</button>
                        </div>
                    </div>
                </mat-card-title>
                <mat-card-content>
                    <div class="row" style="margin-top: 10px;">
                        <div class="col-md-3">
                            Charterer : {{voyage.character}}
                        </div>
                        <div class="col-md-3">
                            Charter Type : {{voyage.characterType}}
                        </div>
                        <div class="col-md-3">
                            Date Of Departure : {{voyage.dateOfDeparture | date:'yyyy-MM-dd'}}
                        </div>
                        <div class="col-md-3">
                            Term Of Delivery : {{voyage.termOfDelivery}}
                        </div>
                    </div>
                    <div class="row" style="margin-top: 10px;">
                        <div class="col-md-3">
                            Place Of Delivery : {{voyage.placeOfDelivery}}
                        </div>
                        <div class="col-md-3">
                            Date Of Delivery : {{voyage.dateOfDelivery | date:'yyyy-MM-dd'}}
                        </div>

                        <div class="col-md-3">
                            Type Of Fuel : <span
                                *ngIf="voyage.fuelTypeIfoInit != null">{{voyage.fuelTypeIfoInit}}</span>
                            <span *ngIf="voyage.fuelTypeMdoInit != null">{{voyage.fuelTypeMdoInit}}</span>
                        </div>
                        <div class="col-md-3">
                            Bunker On Board (MT) : <span
                                *ngIf="voyage.bunkerOnBoardIfoInit != 0">{{voyage.bunkerOnBoardIfoInit}}</span><span
                                *ngIf="voyage.bunkerOnBoardMdoInit != 0">{{voyage.bunkerOnBoardMdoInit}}</span>
                        </div>
                    </div>
                    <div class="row" style="margin-top: 10px;" *ngIf="voyage.bunkeringAt == null">
                        <div class="col-md-3">
                            Loadport : {{voyage.loadPort}}
                        </div>
                        <div class="col-md-3">
                            Discharge Port : {{voyage.dischargePort}}
                        </div>
                    </div>
                    <div *ngIf="voyage.bunkeringAt != null">
                        <div class="row" style="margin-top: 10px;">
                            <div class="col-md-12 text-center" style="font-size: 16px;font-weight: 700;">
                                Bunkering Details
                            </div>
                        </div>
                        <div class="row" style="margin-top: 10px;">
                            <div class="col-md-3">
                                Bunkering At : {{voyage.bunkeringAt}}
                            </div>
                            <div class="col-md-3">
                                Bunker Provider : {{voyage.bunkerProvider}}
                            </div>

                            <div class="col-md-3">
                                Type Of Fuel : <span
                                    *ngIf="voyage.fuelTypeIfoBnk != null">{{voyage.fuelTypeIfoBnk}}</span>
                                <span *ngIf="voyage.fuelTypeMdoBnk != null">{{voyage.fuelTypeMdoBnk}}</span>
                            </div>
                            <div class="col-md-3">
                                Bunker On Board (MT) : <span
                                    *ngIf="voyage.bunkerOnBoardIfoBnk != 0">{{voyage.bunkerOnBoardIfoBnk}}</span><span
                                    *ngIf="voyage.bunkerOnBoardMdoBnk != 0">{{voyage.bunkerOnBoardMdoBnk}}</span>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-3">
                                Bunkering Date : {{voyage.bunkeringDate | date:'yyyy-MM-dd'}}
                            </div>
                            <div class="col-md-3">
                                Loadport : {{voyage.loadPort}}
                            </div>
                            <div class="col-md-3">
                                Discharge Port : {{voyage.dischargePort}}
                            </div>
                        </div>
                    </div>
                    <div *ngIf="voyage.redeliveryAt != null">
                        <div class="row" style="margin-top: 10px;">
                            <div class="col-md-12 text-center" style="font-size: 16px;font-weight: 700;">
                                Re-Delivery Details
                            </div>
                        </div>
                        <div class="row" style="margin-top: 10px;">
                            <div class="col-md-3" style="padding-top: 10px;">
                                Re-Delivery At : {{voyage.redeliveryAt}}
                            </div>
                            <div class="col-md-3" style="padding-top: 10px;">
                                Re-Delivery Date : {{voyage.redeliveryDate | date:'yyyy-MM-dd'}}
                            </div>
                            <div class="col-md-3" style="padding-top: 10px;">
                                Bunker On Board (MT) : <span
                                    *ngIf="voyage.bunkerOnBoardIfoRed != 0">{{voyage.bunkerOnBoardIfoRed}}</span><span
                                    *ngIf="voyage.bunkerOnBoardMdoRed != 0">{{voyage.bunkerOnBoardMdoRed}}</span>
                            </div>
                            <div class="col-md-3">
                                <a mat-stroked-button *ngIf="voyage.redeliveryAt != null"
                                    (click)="viewEmissionData(voyage)">View Emission Data</a>
                            </div>
                        </div>
                    </div>
                </mat-card-content>
            </mat-card>


            <mat-paginator [pageSize]="20" [length]='totalElements' (page)="onPageChange($event)">
            </mat-paginator>
        </div>
        <div [hidden]="!displaySignature">
            <table id="exportTable" mat-table [dataSource]="datasource" class="mat-elevation-z8"
                style="width: 2100px;overflow: auto;">

                <ng-container matColumnDef="voyageNo">
                    <th mat-header-cell *matHeaderCellDef>Voyage No </th>
                    <td mat-cell *matCellDef="let element">
                        {{element.voyageNumber}} </td>
                </ng-container>
                <ng-container matColumnDef="vesselName">
                    <th mat-header-cell *matHeaderCellDef>Vessel Name </th>
                    <td mat-cell *matCellDef="let element">
                        {{element.shipName}} </td>
                </ng-container>

                <ng-container matColumnDef="charterer">
                    <th mat-header-cell *matHeaderCellDef> Charterer </th>
                    <td mat-cell *matCellDef="let element">
                        {{element.character}} </td>
                </ng-container>

                <ng-container matColumnDef="charterType">
                    <th mat-header-cell *matHeaderCellDef>Charter Type</th>
                    <td mat-cell *matCellDef="let element">
                        {{element.characterType}} </td>
                </ng-container>

                <ng-container matColumnDef="dateOfDeparture">
                    <th mat-header-cell *matHeaderCellDef> Date Of Departure</th>
                    <td mat-cell *matCellDef="let element">
                        {{element.dateOfDeparture | date:'yyyy-MM-dd'}} </td>
                </ng-container>

                <ng-container matColumnDef="termOfDelivery">
                    <th mat-header-cell *matHeaderCellDef> Term Of Delivery </th>
                    <td mat-cell *matCellDef="let element">
                        {{element.termOfDelivery}} </td>
                </ng-container>

                <ng-container matColumnDef="placeOfDelivery">
                    <th mat-header-cell *matHeaderCellDef> Place Of Delivery</th>
                    <td mat-cell *matCellDef="let element">
                        {{element.placeOfDelivery}} </td>
                </ng-container>

                <ng-container matColumnDef="dateOfDelivery">
                    <th mat-header-cell *matHeaderCellDef> Date Of Delivery </th>
                    <td mat-cell *matCellDef="let element">
                        {{element.dateOfDelivery | date:'yyyy-MM-dd'}} </td>
                </ng-container>

                <ng-container matColumnDef="fuelTypeInit">
                    <th mat-header-cell *matHeaderCellDef> Type Of Fuel Init</th>
                    <td mat-cell *matCellDef="let element">
                        <ng-container *ngIf="element.fuelTypeIfoInit != null">{{element.fuelTypeIfoInit}}</ng-container>
                        <ng-container *ngIf="element.fuelTypeMdoInit != null">{{element.fuelTypeMdoInit}}</ng-container>
                    </td>
                </ng-container>

                <ng-container matColumnDef="bunkerOnBoardInit">
                    <th mat-header-cell *matHeaderCellDef> Bunker On Board Init(MT) </th>
                    <td mat-cell *matCellDef="let element">
                        <ng-container *ngIf="element.bunkerOnBoardIfoInit != 0">{{element.bunkerOnBoardIfoInit}}
                        </ng-container>
                        <ng-container *ngIf="element.bunkerOnBoardMdoInit != 0">{{element.bunkerOnBoardMdoInit}}
                        </ng-container>
                    </td>
                </ng-container>

                <ng-container matColumnDef="loadport">
                    <th mat-header-cell *matHeaderCellDef> Loadport</th>
                    <td mat-cell *matCellDef="let element">
                        {{element.loadPort}} </td>
                </ng-container>

                <ng-container matColumnDef="dischargePort">
                    <th mat-header-cell *matHeaderCellDef> Discharge Port</th>
                    <td mat-cell *matCellDef="let element">
                        {{element.dischargePort}} </td>
                </ng-container>

                <ng-container matColumnDef="bunkeringAt">
                    <th mat-header-cell *matHeaderCellDef> Bunkering At</th>
                    <td mat-cell *matCellDef="let element">
                        {{element.bunkeringAt}} </td>
                </ng-container>

                <ng-container matColumnDef="bunkerProvider">
                    <th mat-header-cell *matHeaderCellDef> Bunker Provider</th>
                    <td mat-cell *matCellDef="let element">
                        {{element.bunkerProvider}} </td>
                </ng-container>

                <ng-container matColumnDef="bunkeringDate">
                    <th mat-header-cell *matHeaderCellDef> Bunkering Date</th>
                    <td mat-cell *matCellDef="let element">
                        {{element.bunkeringDate | date:'yyyy-MM-dd'}} </td>
                </ng-container>

                <ng-container matColumnDef="fuelTypeBnk">
                    <th mat-header-cell *matHeaderCellDef> Type Of Fuel Bunkering</th>
                    <td mat-cell *matCellDef="let element">
                        <ng-container *ngIf="element.fuelTypeIfoBnk != null">{{element.fuelTypeIfoBnk}}</ng-container>
                        <ng-container *ngIf="element.fuelTypeMdoBnk != null">{{element.fuelTypeMdoBnk}}</ng-container>
                    </td>
                </ng-container>

                <ng-container matColumnDef="bunkerOnBoardBnk">
                    <th mat-header-cell *matHeaderCellDef> Bunker On Board Bunkering(MT) </th>
                    <td mat-cell *matCellDef="let element">
                        <ng-container *ngIf="element.bunkerOnBoardIfoBnk != 0">{{element.bunkerOnBoardIfoBnk}}
                        </ng-container>
                        <ng-container *ngIf="element.bunkerOnBoardMdoBnk != 0">{{element.bunkerOnBoardMdoBnk}}
                        </ng-container>
                    </td>
                </ng-container>

                <ng-container matColumnDef="redeliveryAt">
                    <th mat-header-cell *matHeaderCellDef> Re-Delivery At</th>
                    <td mat-cell *matCellDef="let element">
                        {{element.redeliveryAt}} </td>
                </ng-container>

                <ng-container matColumnDef="redeliveryDate">
                    <th mat-header-cell *matHeaderCellDef> Re-Delivery Date</th>
                    <td mat-cell *matCellDef="let element">
                        {{element.redeliveryDate | date:'yyyy-MM-dd'}} </td>
                </ng-container>

                <ng-container matColumnDef="bunkerOnBoardRed">
                    <th mat-header-cell *matHeaderCellDef> Bunker On Board Redelivery(MT) </th>
                    <td mat-cell *matCellDef="let element">
                        <ng-container *ngIf="element.bunkerOnBoardIfoRed != 0">{{element.bunkerOnBoardIfoRed}}
                        </ng-container>
                        <ng-container *ngIf="element.bunkerOnBoardMdoRed != 0">{{element.bunkerOnBoardMdoRed}}
                        </ng-container>
                    </td>
                </ng-container>

                <tr mat-header-row class="header" *matHeaderRowDef="displayedColumns" style="background: lightgrey;">
                </tr>

                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
        </div>
    </div>
</div>