import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { AdminDetailService } from 'src/app/shared/admin-detail.service';

@Injectable({
    providedIn: 'root'
})
export class AddRedeliveryService {
    constructor(private httpClient: HttpClient,
        private adminDetailService: AdminDetailService) { }


    addRedelivery(formData: any) {
        let url = `${this.adminDetailService.API_URL}/voyage/add/redelivery`;
        return this.httpClient.post(url, formData, this.adminDetailService.header);
    }

}