import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { AdminDetailService } from 'src/app/shared/admin-detail.service';

@Injectable({
    providedIn: 'root'
})
export class AddProfileService {
    constructor(private httpClient: HttpClient,
        private adminDetailService: AdminDetailService) { }

    addVessel(body: any) {
        // const options = { headers: new HttpHeaders().set('Authorization', this.adminDetailService.access_token) }
        let url = `${this.adminDetailService.API_URL}/device`;
        return this.httpClient.post(url, body, this.adminDetailService.header);
    }
}