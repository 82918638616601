import { ReportModule } from './modules/report/report.module';
import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserModule } from '@angular/platform-browser';
import { CommonModule, DatePipe } from '@angular/common';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { MatToolbar, MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { LoginLayoutComponent } from './modules/login-layout/login-layout.component';
import { HomeLayoutComponent } from './modules/home-layout/home-layout.component';
import { NavigationComponent } from './common/navigation/navigation.component';
import { DashboardModule } from './modules/dashboard/dashboard.module';
import { LoginModule } from './modules/user/login/login.module';
import { ProfileModule } from './modules/profile/profile.module';
import { AdminDetailService } from './shared/admin-detail.service';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { GoogleChartsModule } from 'angular-google-charts';
import { HistoryModule } from './modules/history/history.module';
import {
  NgxMatDatetimePickerModule,
  NgxMatNativeDateModule,
  NgxMatTimepickerModule
} from '@angular-material-components/datetime-picker';
import { ReportComponent } from './modules/report/report.component';
import { ExportAsModule } from 'ngx-export-as';
import { AssignmentComponent } from './modules/assignment/assignment.component';
import { AssignmentModule } from './modules/assignment/assignment.module';


@NgModule({
  declarations: [
    AppComponent,
    LoginLayoutComponent,
    HomeLayoutComponent,
    NavigationComponent,
  ],
  imports: [
    AppRoutingModule,
    BrowserAnimationsModule,
    BrowserModule,
    CommonModule,
    MatSidenavModule,
    MatListModule,
    MatIconModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    MatCardModule,
    MatToolbarModule,
    MatButtonModule,
    DashboardModule,
    LoginModule,
    ProfileModule,
    NgxChartsModule,
    GoogleChartsModule,
    HistoryModule,
    NgxMatDatetimePickerModule,
    NgxMatTimepickerModule,
    NgxMatNativeDateModule,
    ReportModule,
    ExportAsModule,
    AssignmentModule
  ],
  providers: [AdminDetailService, DatePipe],
  bootstrap: [AppComponent]
})
export class AppModule { }
