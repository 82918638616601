import { ViewEmissionService } from './view-emission.service';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-view-emission',
  templateUrl: './view-emission.component.html',
  styleUrls: ['./view-emission.component.css']
})
export class ViewEmissionComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    private viewEmissionService: ViewEmissionService,
    public dialogRef: MatDialogRef<ViewEmissionComponent>,) { }
  dataLength: number = 0;
  emissionDataList: any = {};
  ngOnInit(): void {
    this.getEmissionData();
  }

  getEmissionData() {
    this.viewEmissionService.getEmissionDataList(this.data.id).subscribe(
      (res: any) => {
        if (res) {

          this.emissionDataList = res.data;
          this.dataLength = Object.keys(this.emissionDataList).length;
          console.log(this.emissionDataList);
        }
      }
    )
  }

  closeModal() {
    this.dialogRef.close();
  }

}
