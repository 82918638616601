import { AdminDetailService } from './../../../shared/admin-detail.service';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { AddAssignmentService } from './add-assignment.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-add-assignment',
  templateUrl: './add-assignment.component.html',
  styleUrls: ['./add-assignment.component.css']
})
export class AddAssignmentComponent implements OnInit {

  constructor(private fb: FormBuilder,
    public dialogRef: MatDialogRef<AddAssignmentComponent>,
    private adminDetailService: AdminDetailService,
    private addAssignmentService: AddAssignmentService,
    private datePipe: DatePipe) { }

  charterTypeList: any = ['Period', 'Time', 'Voyage'];
  addAssignmentForm !: FormGroup;
  vesselList: any = [];

  ngOnInit(): void {
    this.addAssignmentForm = this.fb.group({
      vessel: ['', Validators.required],
      charterer: [null, Validators.required],
      charterType: [this.charterTypeList[0]],
      dateOfDelivery: [new Date],
      dateOfDeparture: [new Date],
      placeOfDelivery: [null],
      termOfDelivery: ['DLOSP'],
      ifoFuel: [null],
      mdoFuel: [null],
      ifoBoard: [0, Validators.min(0)],
      mdoBoard: [0, Validators.min(0)],
      loadport: [null],
      // bunkeringAt: [null],
      // bunkerProvider: [null],
      // ifoLoaded: [null],
      // mdoLoaded: [null],
      dischargePort: [null],

    })
    if (this.adminDetailService.deviceList.length == 0) {
      this.getVesselList();
    } else {
      this.vesselList = this.adminDetailService.deviceList;
      this.addAssignmentForm.value.vessel = this.vesselList[0];
    }
  }
  getVesselList() {
    this.addAssignmentService.getVesselList().subscribe(
      (res: any) => {
        if (res.statusCode == 200) {
          this.vesselList = res.data;
          this.addAssignmentForm.value.shipId = this.vesselList[0].shipId;
        }
      }, (err) => {
        console.log(err.error.message);
      }
    )
  }

  addAssignment() {
    var formData: any = {};
    formData.bunkerOnBoardIfoInit = this.addAssignmentForm.value.ifoBoard;
    formData.bunkerOnBoardMdoInit = this.addAssignmentForm.value.mdoBoard;
    // formData.bunkerProvider = this.addAssignmentForm.value.bunkerProvider;
    // formData.bunkeringAt = this.addAssignmentForm.value.bunkeringAt;
    formData.character = this.addAssignmentForm.value.charterer;
    formData.characterType = this.addAssignmentForm.value.charterType;
    formData.loadPort = this.addAssignmentForm.value.loadport;
    formData.placeOfDelivery = this.addAssignmentForm.value.placeOfDelivery;
    formData.dischargePort = this.addAssignmentForm.value.dischargePort;
    formData.fuelTypeIfoInit = this.addAssignmentForm.value.ifoFuel;
    formData.fuelTypeMdoInit = this.addAssignmentForm.value.mdoFuel;
    formData.termOfDelivery = this.addAssignmentForm.value.termOfDelivery;
    formData.dateOfDeliveryStr = this.datePipe.transform(this.addAssignmentForm.value.dateOfDelivery, 'yyyy-MM-dd HH:mm:ss');
    formData.userId = this.adminDetailService.userId;
    formData.shipId = this.addAssignmentForm.value.vessel.shipId;
    formData.shipName = this.addAssignmentForm.value.vessel.shipName;
    formData.dateOfDepartureStr = this.datePipe.transform(this.addAssignmentForm.value.dateOfDeparture, 'yyyy-MM-dd HH:mm:ss');
    console.log(formData);
    this.addAssignmentService.addAssignment(formData).subscribe(
      (res: any) => {
        if (res.statusCode == 200) {
          console.log(res);
          alert("Voyage Added Successfully");
          this.dialogRef.close({ vessel: this.addAssignmentForm.value.vessel.shipId, deptDate: this.addAssignmentForm.value.dateOfDeparture });
        }

      }, (err) => {
        alert("Voyage don't added successfully: " + err.error.message);
      }
    )
  }

  closeModal() {
    this.dialogRef.close();
  }

}
