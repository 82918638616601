import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { AdminDetailService } from 'src/app/shared/admin-detail.service';

@Injectable({
    providedIn: 'root'
})
export class ViewEmissionService {
    constructor(private httpClient: HttpClient,
        private adminDetailService: AdminDetailService) { }

    getEmissionDataList(id: any) {
        // const options = { headers: new HttpHeaders().set('token', this.adminDetailService.) }
        let url = `${this.adminDetailService.API_URL}/report/summary/by-voyage?voyage id=${id}`;
        return this.httpClient.get(url, this.adminDetailService.header);
    }

}