import { ReportService } from './report.service';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { MatTableModule } from '@angular/material/table';
import { MatIconModule } from '@angular/material/icon';
import { MatSortModule } from '@angular/material/sort';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { ReportComponent } from './report.component';
import { ReportRoutingModule } from './report-routing.module';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { NgxMatDatetimePickerModule, NgxMatNativeDateModule, NgxMatTimepickerModule } from '@angular-material-components/datetime-picker';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { NoonReportDetailsComponent } from './noon-report-details/noon-report-details.component';

@NgModule({
    declarations: [
        ReportComponent,
        NoonReportDetailsComponent
    ],
    imports: [
        ReactiveFormsModule,
        HttpClientModule,
        CommonModule,
        MatTableModule,
        MatIconModule,
        MatSortModule,
        MatPaginatorModule,
        MatButtonModule,
        MatCardModule,
        MatDialogModule,
        MatFormFieldModule,
        MatInputModule,
        MatSelectModule,
        ReportRoutingModule,
        MatNativeDateModule,
        MatDatepickerModule,
        NgxMatDatetimePickerModule,
        NgxMatTimepickerModule,
        NgxMatNativeDateModule,
        NgxChartsModule,

    ],
    providers: [ReportService, MatDatepickerModule],
    bootstrap: [],
    entryComponents: [NoonReportDetailsComponent]
})
export class ReportModule { }