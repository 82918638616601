import { ViewEmissionService } from './view-emission/view-emission.service';
import { AddBunkeringService } from './add-bunkering/add-bunkering.service';
import { NgxMatDatetimePickerModule, NgxMatNativeDateModule, NgxNativeDateModule } from '@angular-material-components/datetime-picker';
import { AssignmentRoutingModule } from './assignment-routing.module';
import { AssignmentComponent } from './assignment.component';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { MatTableModule } from '@angular/material/table';
import { MatIconModule } from '@angular/material/icon';
import { MatSortModule } from '@angular/material/sort';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { AddAssignmentComponent } from './add-assignment/add-assignment.component';
import { MatDatepicker, MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDividerModule } from '@angular/material/divider';
import { AssignmentService } from './assignment.service';
import { AddAssignmentService } from './add-assignment/add-assignment.service';
import { AddBunkeringComponent } from './add-bunkering/add-bunkering.component';
import { AddRedeliveryComponent } from './add-redelivery/add-redelivery.component';
import { UpdateAssignmentComponent } from './update-assignment/update-assignment.component';
import { UpdateAssignmentService } from './update-assignment/update-assignment.service';
import { AddRedeliveryService } from './add-redelivery/add-redelivery.service';
import { ViewEmissionComponent } from './view-emission/view-emission.component';

@NgModule({
    declarations: [
        AssignmentComponent,
        AddAssignmentComponent,
        AddBunkeringComponent,
        AddRedeliveryComponent,
        UpdateAssignmentComponent,
        ViewEmissionComponent
    ],
    imports: [
        ReactiveFormsModule,
        HttpClientModule,
        CommonModule,
        MatTableModule,
        MatIconModule,
        MatSortModule,
        MatPaginatorModule,
        MatButtonModule,
        MatCardModule,
        MatDialogModule,
        MatFormFieldModule,
        MatInputModule,
        MatSelectModule,
        AssignmentRoutingModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatDividerModule,
        NgxMatDatetimePickerModule,
        NgxMatNativeDateModule,
        NgxNativeDateModule
    ],
    providers: [AssignmentService, AddAssignmentService, UpdateAssignmentService,
        AddRedeliveryService, AddBunkeringService, ViewEmissionService],
    bootstrap: [],
    entryComponents: [AddAssignmentComponent, AddBunkeringComponent,
        AddRedeliveryComponent, UpdateAssignmentComponent, ViewEmissionComponent]
})
export class AssignmentModule { }