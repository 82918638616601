<mat-sidenav-container class="sidenav-container" style="overflow: hidden;">
    <mat-sidenav #drawer [mode]="'side'" class="sidenav" fixedInViewport="true" [attr.role]="'navigation'"
        style="background-color: #454e59;overflow: hidden;">
        <div class="mat-drawer-inner-container ng-tns-c145-0" style="overflow: hidden;">
            <mat-toolbar style="height: 50px;">
                <a [routerLink]="['home']"> <img alt="" src="./assets/logo.png"
                        style="height:30px;width: 40px;padding-right: 10px;" />
                    <span class="hand" style="color: white;">iota<strong>Smart</strong></span>
                </a>
            </mat-toolbar>
            <mat-nav-list class="navigation-list">
                <a style="color: white;" class="navigation-item" mat-list-item [routerLink]="['home']">
                    <mat-icon class="fa fa-ship" style="font-size: 20px !important; padding-right: 2px;"></mat-icon>
                    Live
                </a>

                <mat-divider class="navigation-divider"></mat-divider>
                <a style="color: white;" class="navigation-item" mat-list-item [routerLink]="['history']">
                    <mat-icon class="material-icons">history</mat-icon>History
                </a>
                <mat-divider class="navigation-divider"></mat-divider>
                <a style="color: white;" class="navigation-item" mat-list-item [routerLink]="['report']">
                    <mat-icon class="material-icons">table_rows</mat-icon>Report
                </a>
                <mat-divider class="navigation-divider"></mat-divider>
                <a style="color: white;" class="navigation-item" mat-list-item [routerLink]="['assignment']">
                    <mat-icon class="material-icons">assignment</mat-icon>Voyage
                </a>
                <mat-divider class="navigation-divider"></mat-divider>
                <a style="color: white;" class="navigation-item" mat-list-item [routerLink]="['profile']">
                    <mat-icon class="material-icons">settings</mat-icon>Profile
                </a>
                <mat-divider class="navigation-divider"></mat-divider>
                <a style="color: white;" class="navigation-item" mat-list-item (click)="logout()">
                    <mat-icon class="material-icons">logout</mat-icon>Logout
                </a>
            </mat-nav-list>
        </div>
    </mat-sidenav>
    <mat-sidenav-content class="sidenav-content" style="min-height: 100vh;overflow: hidden;">
        <mat-toolbar class="sidenav-content-toolbar" color="primary" style="height: 50px;">
            <button mat-icon-button class="menu-button" type="button" aria-label="Toggle sidenav"
                (click)="drawer.toggle()">
                <i class="material-icons">menu</i>
            </button>
            <span style="font-size: 12px;">{{adminDetailService.title}}</span>
            <span class="filler">
                <!-- You can place a search bar here or anything you like -->
            </span>
        </mat-toolbar>
        <div id="page-content" style="height: 100%;">
            <router-outlet></router-outlet>
        </div>

    </mat-sidenav-content>
</mat-sidenav-container>