<div class="fleet-bar-div" style="height: 50px;">
    <div class="row fleet-bar static-view" *ngIf="!deviceList">
        <div class="col-lg-12 col-md-12 text-center">
            <div class="row">
                <div class="col-lg-3 col-md-3 fleet-box b-r">
                    <div class="clearfix panel-effect" style="height:48px"></div>
                </div>
                <div class="col-lg-3 col-md-3 fleet-box b-r">
                    <div class="clearfix panel-effect" style="height:48px"></div>
                </div>
                <div class="col-lg-3 col-md-3 fleet-box b-r">
                    <div class="clearfix panel-effect" style="height:48px"></div>
                </div>
                <div class="col-lg-3 col-md-3 fleet-box">
                    <div class="clearfix panel-effect" style="height:48px"></div>
                </div>
            </div>
        </div>
    </div>

    <div class="row fleet-bar" *ngIf="deviceList">
        <div class="col-lg-12 col-md-12 text-center">
            <div class="row row-cols-5">
                <div class="col-xs-2 fleet-box b-r hand" style="width: 19.666667%;" (click)="filter('')">
                    <span class="fleet-value">{{deviceList.length}}</span><br>
                    <span class="fleet-label">Vessel</span>
                </div>
                <div class="col-xs-2 fleet-box b-r hand" style="width: 19.666667%;" (click)="filter('Moving')">
                    <span class="fleet-value color-green"> {{movingCar}}</span><br>
                    <span class="fleet-label color-green">Moving</span>
                </div>
                <div class="col-xs-2 fleet-box b-r hand" style="width: 19.666667%;" (click)="filter('Idle')">
                    <span class="fleet-value color-blue">{{idleCar}} </span><br>
                    <span class="fleet-label color-blue">Idle</span>
                </div>
                <div class="col-xs-2 fleet-box b-r hand" style="width: 19.666667%;" (click)="filter('Parked')">
                    <span class="fleet-value color-red ">{{parkedCar}} </span><br>
                    <span class="fleet-label color-red">Docked</span>
                </div>
                <div class="col-xs-2 fleet-box hand" style="width: 19.666667%;" (click)="filter('In-active')">
                    <span class="fleet-value" style="color:#a7a7a4">{{inActiveCar}} </span><br>
                    <span class="fleet-label" style="color:#a7a7a4">In-active</span>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="row" style="margin-left: -17px;margin-right: -20px;">
    <div class="col-sm-2"
        style="height: 83vh;background-color: #f1f1f1!important; overflow-y: auto; padding-left: 1px; padding-right: 1px;">
        <div class="text-center" style="font-size: 13px;" *ngIf="filteredList.length === 0">No Vessel Found</div>
        <div class="vehicle-card" [ngClass]="{'card-selected': device.selected}" (click)="changeDevice(device)"
            *ngFor="let device of filteredList" style="margin-bottom: 10px;">
            <div class="widget" style="margin-bottom: 0px;">
                <div class="widget-content p" *ngIf="device.active">
                    <span><b>{{device.shipName}}</b></span>
                    <span class="pull-right hand text-muted">
                        <mat-icon style="font-size: 11px !important;width: auto;"
                            [ngClass]="{'color-green': device.isMoving, 'color-blue':device.isIdle}">circle
                        </mat-icon>
                        {{device.movingText}}
                    </span>
                </div>

                <!-- <div class="widget-content p border-top clearfix">
                    <i class="fa fa-clock-o"></i>
                    <span *ngIf="device.lastUpdate"> {{device.lastUpdate*1000 | date:'medium'}} </span>
                    <span *ngIf="!device.lastUpdate"> Time not available!</span> <br />

                </div> -->
                <div class="widget-content p border-top clearfix">
                    <div class="row m-lr-ve">
                        <div class="col-sm-12 p-l-10">
                            Start At:
                            <span style="font-weight: 700;" *ngIf="!device.startAt"> Time not available!</span>
                            <span style="font-weight: 700;" *ngIf="device.startAt"> {{device.startAt}} </span><br />
                            Current:
                            <span style="font-weight: 700;" *ngIf="device.lastUpdate"> {{device.lastUpdate}}
                            </span>
                            <span style="font-weight: 700;" *ngIf="!device.lastUpdate"> Time not available!</span>
                            <br />
                            Location:
                            <span style="font-weight: 700;" *ngIf="!device.lastAddress"> N/A</span>
                            <span style="font-weight: 700;" *ngIf="device.lastAddress"> {{device.lastAddress}}</span>
                        </div>

                    </div>
                </div>

                <div class="widget-content p border-top clearfix border-bottom">
                    <div class="row m-lr-ve">
                        <div class="col-sm-12 p-l-10 hand">
                            <i class="flaticon-car"></i> Engine
                            <span *ngIf="device.engineStatus=='Ok'">is <span
                                    class="color-green"><strong>OK</strong></span></span>
                            <span *ngIf="device.engineStatus!='Ok'">has <span
                                    class="color-red"><strong>problems!</strong></span></span>
                        </div>
                    </div>
                    <div class="row m-lr-ve">
                        <div class="col-sm-12 p-l-10">
                            <i class="flaticon-car"></i> Emission Health -
                            <span class="color-green" *ngIf="device.emissionStatus=='Ok'"><strong>OK</strong></span>
                            <span class="color-red" *ngIf="device.emissionStatus!='Ok'"><strong>problems</strong></span>
                        </div>
                    </div>
                </div>
                <div class="widget-content p border-top clearfix">
                    <div class="row m-lr-ve text-center">

                        <div class="col-sm-4 push-inner-top-bottom border-right" style="padding: 0px;">
                            <span>NOx<br />(Avg. ppm)</span><br />
                            <b><span *ngIf="!device.cumNOx">0</span>
                                <span *ngIf="device.cumNOx">{{device.cumNOx | number:'.1-2'}}</span>
                            </b>
                        </div>
                        <div class="col-sm-4 push-inner-top-bottom border-right" style="padding: 0px;">
                            <span>SOx<br />(Avg. ppm)</span>
                            <br /> <b><span *ngIf="!device.cumSOx">0</span>
                                <span *ngIf="device.cumSOx">{{device.cumSOx | number:'.1-2'}}</span></b>

                        </div>
                        <div class="col-sm-4 push-inner-top-bottom border-right" style="padding: 0px;">
                            <span>CO<br />(Avg. ppm)</span>
                            <br /><b><span *ngIf="!device.cumCO">0</span>
                                <span *ngIf="device.cumCO">{{device.cumCO | number:'.1-2'}}</span></b>

                        </div>


                    </div>
                </div>
                <div class="widget-content p border-top clearfix">
                    <div class="row m-lr-ve text-center">
                        <div class="col-sm-6 push-inner-top-bottom border-right" style="padding: 0px;">
                            <span>CO2<br />(%V/V)</span><br />
                            <b><span *ngIf="!device.cumCO2">0</span>
                                <span *ngIf="device.cumCO2">{{device.cumCO2 | number:'.1-2'}}</span>
                            </b>
                        </div>
                        <div class="col-sm-6 push-inner-top-bottom border-right" style="padding: 0px;">
                            <span>O2<br />(%V/V)</span> <br />
                            <b><span *ngIf="!device.cumO2">0</span>
                                <span *ngIf="device.cumO2">{{device.cumO2 | number:'.1-2'}}</span>
                            </b>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
    <div class="col-sm-6" style="padding-left:1px;padding-right: 1px;background-color: #ffffff;"
        *ngIf="tabView == 'alerts'">
        <div class="tab" style="height: 38px;">
            <button class="tablinks" (click)="toggleMiddleWindow('live')">Live</button>
            <button class="tablinks active">Alerts</button>

        </div>
        <div class="widget" style="margin-bottom: 0px;background-color: #f1f1f1!important;">
            <div class="widget-content" style="padding: 5px 2px 2px 2px;">
                <div class="multiview-content">
                    <div class="row margin-top bg-color-white" *ngIf="alertList.length==0">
                        <div class="col-lg-12 col-md-12 text-center">
                            <span><b>You can see live alerts here</b></span>
                        </div>
                    </div>
                    <div class="row margin-top bg-color-white" *ngIf="alertList.length > 0">
                        <div class="col-lg-12">
                            <div class="row">
                                <div class="col-lg-12 col-md-12">
                                    <div class="widget-content p border-top clearfix" *ngFor="let alert of alertList">
                                        <span style="font-size: 12px;">{{alert.message}}</span>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>
            </div>
        </div>

    </div>
    <div class="col-sm-6" style="padding-left:1px;padding-right: 1px;background-color: #ffffff;"
        *ngIf="tabView == 'live'">
        <div class="tab" style="height: 38px;">
            <button class="tablinks active">Live</button>
            <button class="tablinks" (click)="toggleMiddleWindow('alerts')">Alerts</button>

        </div>
        <div class="widget" style="margin-bottom: 0px;background-color: #f1f1f1!important;">
            <div class="widget-content" style="padding: 0px 2px 2px 2px;">
                <div class="multiview-content">
                    <div class="row margin-top bg-color-white" *ngIf="!selectedDevice.isGraphData">
                        <div class="col-lg-12 col-md-12 text-center">
                            <span><b>You can see live graph when vehicle will move</b></span>
                        </div>
                    </div>
                    <ng-container *ngIf="selectedDevice.isGraphData">
                        <div class="row margin-top">
                            <div class="col-lg-5 bg-color-white">
                                <google-chart style="margin: auto;" [title]="coChart.title" [type]="coChart.type"
                                    [data]="coChart.data" [options]="coChart.options">
                                </google-chart>
                            </div>
                            <div class="col-lg-7">
                                <div style="margin-left: 1px; background-color: white;">
                                    <ngx-charts-line-chart [view]="viewLineChart" [scheme]="coColorScheme"
                                        [results]="coLineChartEmissionData" [gradient]="gradient" [xAxis]="showXAxis"
                                        [yAxis]="showYAxis" [showXAxisLabel]="showXAxisLabel"
                                        [referenceLines]="coReferenceLine" [showYAxisLabel]="showYAxisLabel"
                                        [xAxisLabel]="xAxisLabel" [yAxisLabel]="yAxisCOLabel" [autoScale]="autoScale"
                                        [timeline]="false" [showRefLines]="true" [showRefLabels]="true">
                                    </ngx-charts-line-chart>
                                </div>
                            </div>
                        </div>
                        <div class="row margin-top">
                            <div class="col-lg-5 bg-color-white">
                                <google-chart style="margin: auto;" [title]="noxChart.title" [type]="noxChart.type"
                                    [data]="noxChart.data" [options]="noxChart.options">
                                </google-chart>
                            </div>
                            <div class="col-lg-7">
                                <div style="margin-left: 1px; background-color: white;">
                                    <ngx-charts-line-chart [view]="viewLineChart" [scheme]="noxColorScheme"
                                        [results]="noxLineChartEmissionData" [gradient]="gradient" [xAxis]="showXAxis"
                                        [yAxis]="showYAxis" [showXAxisLabel]="showXAxisLabel"
                                        [showYAxisLabel]="showYAxisLabel" [xAxisLabel]="xAxisLabel"
                                        [yAxisLabel]="yAxisNOxLabel" [autoScale]="autoScale" [timeline]="false"
                                        [showRefLines]="true" [showRefLabels]="true"
                                        [referenceLines]="noxReferenceLine">
                                    </ngx-charts-line-chart>
                                </div>
                            </div>
                        </div>
                        <div class="row margin-top">
                            <div class="col-lg-5 bg-color-white">
                                <google-chart style="margin: auto;" [title]="soxChart.title" [type]="soxChart.type"
                                    [data]="soxChart.data" [options]="soxChart.options">
                                </google-chart>
                            </div>
                            <div class="col-lg-7">
                                <div style="margin-left: 1px; background-color: white;">
                                    <ngx-charts-line-chart [view]="viewLineChart" [scheme]="soxColorScheme"
                                        [results]="soxLineChartEmissionData" [gradient]="gradient" [xAxis]="showXAxis"
                                        [yAxis]="showYAxis" [showXAxisLabel]="showXAxisLabel"
                                        [showYAxisLabel]="showYAxisLabel" [xAxisLabel]="xAxisLabel"
                                        [yAxisLabel]="yAxisSOxLabel" [autoScale]="autoScale" [timeline]="false"
                                        [showRefLines]="true" [showRefLabels]="true"
                                        [referenceLines]="soxReferenceLine">
                                    </ngx-charts-line-chart>
                                </div>
                            </div>
                        </div>
                        <div class="row margin-top">
                            <div class="col-lg-5 bg-color-white">
                                <google-chart style="margin: auto;" [title]="o2Chart.title" [type]="o2Chart.type"
                                    [data]="o2Chart.data" [options]="o2Chart.options">
                                </google-chart>
                            </div>
                            <div class="col-lg-7">
                                <div style="margin-left: 1px; background-color: white;">
                                    <ngx-charts-line-chart [view]="viewLineChart" [scheme]="o2ColorScheme"
                                        [results]="o2LineChartEmissionData" [gradient]="gradient" [xAxis]="showXAxis"
                                        [yAxis]="showYAxis" [showXAxisLabel]="showXAxisLabel"
                                        [showYAxisLabel]="showYAxisLabel" [xAxisLabel]="xAxisLabel"
                                        [yAxisLabel]="yAxisO2Label" [autoScale]="autoScale" [timeline]="false"
                                        [showRefLines]="true" [showRefLabels]="true" [referenceLines]="o2ReferenceLine">
                                    </ngx-charts-line-chart>
                                </div>
                            </div>
                        </div>
                        <div class="row margin-top">
                            <div class="col-lg-5 bg-color-white">
                                <google-chart style="margin: auto;" [title]="co2Chart.title" [type]="co2Chart.type"
                                    [data]="co2Chart.data" [options]="co2Chart.options">
                                </google-chart>
                            </div>
                            <div class="col-lg-7">
                                <div style="margin-left: 1px; background-color: white;">
                                    <ngx-charts-line-chart [view]="viewLineChart" [scheme]="co2ColorScheme"
                                        [results]="co2LineChartEmissionData" [gradient]="gradient" [xAxis]="showXAxis"
                                        [yAxis]="showYAxis" [referenceLines]="co2ReferenceLine"
                                        [showXAxisLabel]="showXAxisLabel" [showYAxisLabel]="showYAxisLabel"
                                        [xAxisLabel]="xAxisLabel" [yAxisLabel]="yAxisCO2Label" [autoScale]="autoScale"
                                        [timeline]="false" [showRefLines]="true" [showRefLabels]="true">
                                    </ngx-charts-line-chart>
                                </div>
                            </div>
                        </div>
                        <div class="row margin-top">
                            <div class="col-lg-5 bg-color-white">
                                <google-chart style="margin: auto;" [title]="speedChart.title" [type]="speedChart.type"
                                    [data]="speedChart.data" [options]="speedChart.options">
                                </google-chart>
                            </div>
                            <div class="col-lg-7">
                                <div style="margin-left: 1px;background-color: white;">
                                    <ngx-charts-line-chart [view]="viewLineChart" [scheme]="speedColorScheme"
                                        [results]="speedLineChartData" [gradient]="gradient" [xAxis]="showXAxis"
                                        [yAxis]="showYAxis" [showXAxisLabel]="showXAxisLabel"
                                        [showYAxisLabel]="showYAxisLabel" [xAxisLabel]="xAxisLabel"
                                        [yAxisLabel]="yAxisSpeedLabel" [autoScale]="autoScale" [timeline]="false">
                                    </ngx-charts-line-chart>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
    <div class="col-sm-4" style="padding-left: 1px;padding-right: 1px;">
        <div #mapContainer id="map" style="height: 100%; width: 100%;"></div>
    </div>
</div>