<mat-card>
    <mat-card-title class="text-center" style="font-size: 16px;font-weight: 700;">
        Voyage No : {{data.voyageNumber}}
        <div *ngIf="dataLength == 0">
            No Data Found!
        </div>
    </mat-card-title>
    <mat-card-content>
        <ng-container *ngIf="emissionDataList.beforeBunker">
            <div class="text-center" style="font-size: 15px;font-weight: 500;">
                Before Bunkering
            </div>
            <div class="row">
                <div class="col-md-6">
                    <div class="row">
                        <div class="col-md-8">
                            SOx(Avg. ppm) :
                        </div>
                        <div class="col-md-4">
                            <b>{{emissionDataList.beforeBunker.sox/emissionDataList.beforeBunker.count |
                                number:'.1-2'}}</b>
                        </div>
                    </div>
                </div>

                <div class="col-md-6">
                    <div class="row">
                        <div class="col-md-8">
                            CO(Avg. ppm) :
                        </div>
                        <div class="col-md-4">
                            <b>{{emissionDataList.beforeBunker.crbnMoOx/emissionDataList.beforeBunker.count |
                                number:'.1-2'}}</b>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <div class="row">
                        <div class="col-md-8">
                            O2(%V/V) :
                        </div>
                        <div class="col-md-4">
                            <b>{{emissionDataList.beforeBunker.oxi/emissionDataList.beforeBunker.count |
                                number:'.1-2'}}</b>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="row">
                        <div class="col-md-8">
                            CO2(%V/V) :
                        </div>
                        <div class="col-md-4">
                            <b>{{emissionDataList.beforeBunker.crbnDiOx/emissionDataList.beforeBunker.count |
                                number:'.1-2'}}</b>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <div class="row">
                        <div class="col-md-8">
                            NOx(Avg. ppm) :
                        </div>
                        <div class="col-md-4">
                            <b>{{emissionDataList.beforeBunker.nitrOxi/emissionDataList.beforeBunker.count |
                                number:'.1-2'}}</b>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="row">
                        <div class="col-md-8">
                            Distance(in Nm) :
                        </div>
                        <div class="col-md-4">
                            <b>{{emissionDataList.beforeBunker.distance | number:'.1-2'}}</b>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="emissionDataList.afterBunker">
            <div class="text-center" style="font-size: 15px;font-weight: 500;">
                After Bunkering
            </div>
            <div class="row">

                <div class="col-md-6">
                    <div class="row">
                        <div class="col-md-8">
                            SOx(Avg. ppm) :
                        </div>
                        <div class="col-md-4">
                            <b>{{emissionDataList.afterBunker.sox/emissionDataList.afterBunker.count |
                                number:'.1-2'}}</b>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="row">
                        <div class="col-md-8">
                            CO(Avg. ppm) :
                        </div>
                        <div class="col-md-4">
                            <b>{{emissionDataList.afterBunker.crbnMoOx/emissionDataList.afterBunker.count |
                                number:'.1-2'}}</b>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <div class="row">
                        <div class="col-md-8">
                            O2(%V/V) :
                        </div>
                        <div class="col-md-4">
                            <b>{{emissionDataList.afterBunker.oxi/emissionDataList.afterBunker.count |
                                number:'.1-2'}}</b>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="row">
                        <div class="col-md-8">
                            CO2(%V/V) :
                        </div>
                        <div class="col-md-4">
                            <b>{{emissionDataList.afterBunker.crbnDiOx/emissionDataList.afterBunker.count |
                                number:'.1-2'}}</b>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <div class="row">
                        <div class="col-md-8">
                            NOx(Avg. ppm) :
                        </div>
                        <div class="col-md-4">
                            <b>{{emissionDataList.afterBunker.nitrOxi/emissionDataList.afterBunker.count |
                                number:'.1-2'}}</b>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="row">
                        <div class="col-md-8">
                            Distance(in Nm) :
                        </div>
                        <div class="col-md-4">
                            <b>{{emissionDataList.afterBunker.distance | number:'.1-2'}}</b>
                        </div>
                    </div>
                </div>
            </div>

        </ng-container>
        <div class="text-right">
            <button mat-raised-button color="warn" style="margin-right: 10px;" (click)="closeModal()">Close
            </button>
        </div>
    </mat-card-content>
</mat-card>