import { Component, OnInit } from '@angular/core';
import { LoginService } from '../user/login/login.service';
import { AdminDetailService } from 'src/app/shared/admin-detail.service';

@Component({
  selector: 'app-home-layout',
  templateUrl: './home-layout.component.html',
  styleUrls: ['./home-layout.component.css']
})
export class HomeLayoutComponent implements OnInit {

  constructor(private loginService: LoginService,
    private adminDetailService: AdminDetailService) { }

  ngOnInit(): void {
    // this.getUserContext()
  }

}
