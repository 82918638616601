import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AdminDetailService } from 'src/app/shared/admin-detail.service';

@Injectable({
    providedIn: 'root'
})
export class HistoryService {
    constructor(private httpClient: HttpClient,
        private adminDetailService: AdminDetailService) { }

    getVesselList() {
        let url = `${this.adminDetailService.API_URL}/device/list?ownerId=${this.adminDetailService.userId}`;
        return this.httpClient.get(url, this.adminDetailService.header);
    }

    // getTripTimeline(id: any, first: any, last: any, page: any) {
    //     let url = `${this.adminDetailService.API_URL}/report/list?shipId=${id}&firstDate=${first}&lastDate=${last}&pageNumber=${page}`;
    //     return this.httpClient.get(url, this.adminDetailService.header);
    // }

    getTripTimeline(id: any, first: any, last: any, page: any) {
        let url = `${this.adminDetailService.API_URL}/report/history-list?shipId=${id}&firstDate=${first}&lastDate=${last}`;
        return this.httpClient.get(url, this.adminDetailService.header);
    }
    getVoyageList(shipId: any) {
        // const options = { headers: new HttpHeaders().set('token', this.adminDetailService.) }
        let url = `${this.adminDetailService.API_URL}/voyage/list/by-ship?shipId=${shipId}`;
        return this.httpClient.get(url, this.adminDetailService.header);
    }
}