<mat-card style="overflow: hidden;">
    <mat-card-title style="border-bottom: 2px solid #dae0e8; overflow: hidden;">
        <h2 style="margin: 0 0 5px;">Add Voyage</h2>
    </mat-card-title>
    <mat-card-content style="overflow-y: auto;height: 450px;">
        <form [formGroup]="addAssignmentForm">
            <mat-form-field class="example-full-width">
                <mat-label>Vessel Name</mat-label>
                <mat-select formControlName="vessel" required>
                    <mat-option value="">Select Vessel</mat-option>
                    <mat-option [value]="vessel" *ngFor="let vessel of vesselList">
                        {{vessel.shipName}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field class="example-full-width">
                <mat-label>Charterer</mat-label>
                <input matInput formControlName="charterer">
            </mat-form-field>
            <mat-form-field class="example-full-width">
                <mat-label>Charter Type</mat-label>
                <mat-select formControlName="charterType" required>
                    <mat-option *ngFor="let group of charterTypeList" [value]="group">
                        {{group}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field class="example-full-width" appearance="fill">
                <mat-label>Date Of Departure</mat-label>
                <input matInput [matDatepicker]="picker1" formControlName="dateOfDeparture">
                <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                <mat-datepicker #picker1></mat-datepicker>
            </mat-form-field>
            <mat-form-field class="example-full-width">
                <mat-label>Term Of Delivery</mat-label>
                <mat-select formControlName="termOfDelivery">
                    <mat-option value="DLOSP">
                        DLOSP
                    </mat-option>
                    <mat-option value="APS">
                        APS
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field class="example-full-width">
                <mat-label>Place Of Delivery</mat-label>
                <input matInput formControlName="placeOfDelivery">
            </mat-form-field>
            <mat-form-field class="example-full-width" appearance="fill">
                <mat-label>Date Of Delivery</mat-label>
                <input matInput [matDatepicker]="picker" formControlName="dateOfDelivery">
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
            <mat-label>Type Of Fuel</mat-label>
            <mat-form-field class="example-full-width">
                <mat-label>IFO</mat-label>
                <input matInput formControlName="ifoFuel">
            </mat-form-field>
            <mat-form-field class="example-full-width">
                <mat-label>MDO</mat-label>
                <input matInput formControlName="mdoFuel">
            </mat-form-field>
            <mat-label> Bunker On Board (MT)</mat-label>
            <mat-form-field class="example-full-width">
                <mat-label>IFO(MT)</mat-label>
                <input matInput formControlName="ifoBoard">
            </mat-form-field>
            <mat-form-field class="example-full-width">
                <mat-label>MDO(MT)</mat-label>
                <input matInput formControlName="mdoBoard">
            </mat-form-field>
            <mat-form-field class="example-full-width">
                <mat-label>Loadport</mat-label>
                <input matInput formControlName="loadport">
            </mat-form-field>
            <!-- <mat-form-field class="example-full-width">
                <mat-label>Bunkering At</mat-label>
                <input matInput formControlName="bunkeringAt">
            </mat-form-field>
            <mat-form-field class="example-full-width">
                <mat-label>Bunker Provider</mat-label>
                <input matInput formControlName="bunkerProvider">
            </mat-form-field> -->
            <mat-form-field class="example-full-width">
                <mat-label>Discharge Port</mat-label>
                <input matInput formControlName="dischargePort">
            </mat-form-field>

            <div class="text-right">
                <button mat-raised-button color="primary" [disabled]="!addAssignmentForm.valid"
                    style="margin-right: 1px;" (click)="addAssignment()">Submit
                </button>
                <button mat-raised-button color="warn" style="margin-right: 10px;" (click)="closeModal()">Cancel
                </button>
            </div>

        </form>
    </mat-card-content>
</mat-card>