<img src="./assets/full_bg.jpg" alt="Full Background" style="width: 100vw;overflow: hidden;">
<!-- END Full Background -->
<!-- Login Container -->
<div id="login-container">
    <h3 class="h2 text-light push-top-bottom text-center animation-pullDown">
        <img alt="" src="./assets/logo.png" style="max-height: 30px;max-width: 30px;"> <strong> iGreen</strong>
    </h3>
    <mat-card>
        <mat-card-title style="border-bottom: 2px solid #dae0e8;">
            <h2 style="margin: 0 0 5px;">Login</h2>
        </mat-card-title>
        <mat-card-content *ngIf="msg" class="help-block animation-pullUp err-color">{{msg}}</mat-card-content>
        <mat-card-content>
            <form [formGroup]="loginForm" (ngSubmit)="userLogin()">
                <p>
                    <mat-form-field class="example-full-width">
                        <mat-label>UserName</mat-label>
                        <input matInput formControlName="username">
                    </mat-form-field>
                </p>

                <p>
                    <mat-form-field class="example-full-width">
                        <mat-label>Password</mat-label>
                        <input matInput type="password" formControlName="password">
                    </mat-form-field>
                </p>

                <div class="text-right">
                    <button mat-raised-button style="margin-right: 10px; background-color: #afde5c; color: white;"
                        [disabled]="!loginForm.valid">Log In
                    </button>
                </div>

            </form>
        </mat-card-content>
    </mat-card>
</div>