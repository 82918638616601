import { AdminDetailService } from './../../shared/admin-detail.service';
import { DatePipe } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { GoogleChartComponent } from 'angular-google-charts';
import { HistoryService } from './history.service';
var MarkerWithLabel = require('markerwithlabel')(google.maps);

@Component({
  selector: 'app-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.css']
})
export class HistoryComponent implements OnInit {
  maxDate = new Date();
  constructor(private historyService: HistoryService,
    private datePipe: DatePipe,
    private fb: FormBuilder,
    public adminDetailService: AdminDetailService) {
    this.adminDetailService.title = "History";

  }

  @ViewChild('googlechart')
  googlechart!: GoogleChartComponent;
  //map options
  @ViewChild('mapContainer', { static: false })
  gmap!: ElementRef;
  map!: google.maps.Map;
  lat = 22.55;
  lng = 88.333333;
  target: any = 0;
  play = { show: true };
  pointInterval: any;
  moveInterval: any;
  marker: any;
  location = this.lat + ',' + this.lng;
  speed = 0;
  playback_speed = 50000;
  time: any;
  coordinates = new google.maps.LatLng(this.lat, this.lng);

  mapOptions: google.maps.MapOptions = {
    center: this.coordinates,
    zoom: 8,
    mapTypeControl: false,
    streetViewControl: false,
  };

  voyageList: any = [];
  range!: FormGroup;
  markerA: any;
  markerB: any;
  polyline: any = [];
  //map option end
  date: any = {}
  pageNumber: number = 0;
  vesselList: any = [];
  startTime: any;
  endTime: any;
  startLocation: any;
  endLocation: any;
  durationOfTrip: any = 0;
  cumCO2 = 0;
  cumCO = 0;
  cumO2 = 0;
  cumNOx = 0;
  cumSOx = 0;

  ngOnInit(): void {
    this.range = this.fb.group({
      fromDate: [new Date()],
      toDate: [new Date()]
    })
    this.maxDate.setDate(this.maxDate.getDate());
    if (this.adminDetailService.deviceList.length == 0) {
      this.getVesselList();
    } else {
      this.vesselList = this.adminDetailService.deviceList;
      this.vessel = this.vesselList[0].shipId;
      this.selectedDevice = this.vesselList[0];
      this.selectedDevice.selected = true;
      this.getVoyageList();
      if (this.adminDetailService.status == "ONLINE") {
        console.log(this.vessel, this.vesselList)
        this.getTripTimeline(0, 0, this.pageNumber);
      }
    }

  }
  ngAfterViewInit() {
    this.mapInitializer();
  }

  mapInitializer() {
    this.map = new google.maps.Map(this.gmap.nativeElement,
      this.mapOptions);

  }
  message!: string;
  deviceList: any;
  tabView = 'live';
  selectedDevice: any = null;

  public disabled = false;
  public showSpinners = true;
  public showSeconds = false;
  public touchUi = false;
  public enableMeridian = false;
  public stepHour = 1;
  public stepMinute = 1;
  public stepSecond = 1;

  historyRange = 'recent';
  vessel = '';
  tripTimelineList: any = [];
  paths: any = [];
  markers: any = [];
  alertList: any = [];
  voyageSelected: any = '';

  getTripTimeline(first: any, last: any, page: any) {
    let cum_co2 = 0;
    let cum_co = 0;
    let cum_sox = 0;
    let cum_nox = 0;
    let cum_o2 = 0;
    let cum_fuel = 0;
    this.target = 0;
    if (this.polyline.length > 0) {
      for (let k = 0; k < this.polyline.length; k++) {
        this.polyline[k].setMap(null);
      }
      this.polyline.length = 0;
    }
    if (this.paths.length > 0) {

      this.paths = [];
    }
    if (this.marker != null)
      this.marker.setMap(null);
    if (this.markerA != null)
      this.markerA.setMap(null);
    if (this.markerB != null)
      this.markerB.setMap(null);
    this.markers = [];

    this.historyService.getTripTimeline(this.vessel, first, last, page).subscribe(
      (res: any) => {
        console.log(res);
        if (res) {
          this.tripTimelineList = res.data;
          if (this.tripTimelineList.length > 0) {
            let tripLength = this.tripTimelineList.length;
            this.speed = 0;
            this.location = this.tripTimelineList[0].lat + ',' + this.tripTimelineList[0].lng;
            this.time = this.datePipe.transform(this.tripTimelineList[0].ts * 1000, 'medium', this.adminDetailService.offset);;
            this.startLocation = this.tripTimelineList[0].lat + ',' + this.tripTimelineList[0].lng;
            this.startTime = this.datePipe.transform(this.tripTimelineList[0].ts * 1000, 'medium', this.adminDetailService.offset);
            this.endLocation = this.tripTimelineList[tripLength - 1].lat + ',' + this.tripTimelineList[tripLength - 1].lng;
            this.endTime = this.datePipe.transform(this.tripTimelineList[tripLength - 1].ts * 1000, 'medium', this.adminDetailService.offset);
            console.log(this.startTime, this.endTime)
            let diffInMilli = Math.abs(this.tripTimelineList[0].ts - this.tripTimelineList[tripLength - 1].ts);
            this.durationOfTrip = Math.floor(diffInMilli / (60 * 60)) + ':' + Math.floor((diffInMilli % (60 * 60)) / 60);

            for (let i = 0; i < this.tripTimelineList.length; i++) {
              cum_co2 += this.tripTimelineList[i].crbnDiOx;
              cum_co += this.tripTimelineList[i].crbnMoOx;
              cum_sox += this.tripTimelineList[i].sox;
              cum_nox += this.tripTimelineList[i].nitrOxi;
              cum_o2 += this.tripTimelineList[i].oxi;
              let path = { lat: this.tripTimelineList[i].lat, lng: this.tripTimelineList[i].lng };
              this.paths.push(path);
              console.log(this.tripTimelineList[i].lat, this.tripTimelineList[i].lng,)
            }
            if (tripLength > 0) {
              this.cumCO2 = cum_co2 / tripLength;
              this.cumCO = cum_co / tripLength;
              this.cumNOx = cum_nox / tripLength;
              this.cumSOx = cum_sox / tripLength;
              this.cumO2 = cum_o2 / tripLength;
            } else {
              this.cumCO2 = 0;
              this.cumCO = 0;
              this.cumNOx = 0;
              this.cumSOx = 0;
              this.cumO2 = 0;
            }

            this.createPath();
          }

        }
      }, (err) => {
        alert("Data don't get successfully: " + err.error.message);
      }
    )
  }

  getVoyageList() {
    this.historyService.getVoyageList(this.vessel).subscribe(
      (res: any) => {
        if (res.statusCode == 200) {
          this.voyageList = res.data.content;
          // this.voyageSelected = this.voyageList[0];
        }
      }, (err) => {
        alert("Data don't get successfully: " + err.error.message);
      }
    )
  }

  voyageChangeApply() {
    let firstDate = new Date(this.voyageSelected.dateOfDeparture);
    console.log(this.voyageSelected);
    if (this.voyageSelected.redeliveryDate == null) {
      var lastDate = new Date;
    } else {
      lastDate = new Date(this.voyageSelected.redeliveryDate);
    }
    this.date.fromDate = firstDate;
    this.date.toDate = lastDate;
    this.getTripTimeline(this.date.fromDate.getTime(), this.date.toDate.getTime(), this.pageNumber)
  }

  markerIcon = {
    path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
    scale: 7,
    strokeColor: '#9c9c9c',
    strokeWeight: .10,
    fillOpacity: 1,
    fillColor: '#0D3340',
    rotation: 0,
    anchor: new google.maps.Point(0, 2),
  };

  createPath() {
    var oldLat = 0;
    var oldLng = 0;
    var heading = 0;
    if (this.paths) {
      for (let i = 0; i < this.paths.length; i++) {
        let values = this.paths[i];
        if (oldLat != values.lat && oldLng != values.lng) {
          var oldPosition = new google.maps.LatLng(oldLat, oldLng);
          oldLat = values.lat;
          oldLng = values.lng;

          var newPosition = new google.maps.LatLng(values.lat, values.lng);
          heading = google.maps.geometry.spherical.computeHeading(oldPosition, newPosition);
          var cord = { point: [values.lat, values.lng], heading: heading };
          this.markers.push(cord);
        }
      }
      this.markerA = new google.maps.Marker({
        position: {
          lat: this.markers[0].point[0],
          lng: this.markers[0].point[1]
        },
        map: this.map,
        label: 'A'
      });
      this.markerB = new google.maps.Marker({
        position: {
          lat: this.markers[this.markers.length - 1].point[0],
          lng: this.markers[this.markers.length - 1].point[1]
        },
        map: this.map,
        label: 'B'
      });
      this.marker = new MarkerWithLabel({
        position: this.markerA.getPosition(),
        map: this.map,
        icon: this.markerIcon,
        labelContent: '',
        labelAnchor: new google.maps.Point(-15, 10),
        labelClass: "marker-play-labels",
        labelStyle: { opacity: 0.75 }
      });
      var bounds = new google.maps.LatLngBounds();
      bounds.extend(this.markerA.getPosition());
      bounds.extend(this.markerB.getPosition());
      for (var p = 0; p < this.paths.length; p++) {
        bounds.extend(this.paths[p]);
      }
      this.map.fitBounds(bounds);

      this.createPolyline();
    }
  }
  createPolyline() {
    for (let l = 0; l < this.paths.length - 1; l++) {

      let polyCoordinates = [];
      polyCoordinates.push(new google.maps.LatLng(this.paths[l]['lat'], this.paths[l]['lng']));
      polyCoordinates.push(new google.maps.LatLng(this.paths[l + 1]['lat'], this.paths[l + 1]['lng']));
      let polyLine = new google.maps.Polyline({
        path: polyCoordinates,
        strokeOpacity: 1,
        strokeWeight: 3,
        geodesic: true,
        strokeColor: 'blue'
      });
      this.polyline.push(polyLine);
    }
    for (let k = 0; k < this.polyline.length; k++) {
      this.polyline[k].setMap(this.map);
    }
  }
  resetGraphs() {
    this.speed = 0;
    this.location = this.tripTimelineList[0].lat + ',' + this.tripTimelineList[0].lng;
    this.time = new Date(this.tripTimelineList[0].ts);
    this.co2Chart.data = [
      ['CO2', 0]
    ],
      this.coChart.data = [
        ['CO', 0]
      ]
    this.noxChart.data = [
      ['NOx', 0]
    ]

    this.soxChart.data = [
      ['SOx', 0]
    ]
    this.o2Chart.data = [
      ['O2', 0]
    ]
    this.speedChart.data = [
      ['Speed', 0]
    ]
    this.co2LineChartEmissionData = [
      {
        "name": 'CO2(%V/V)',
        "series": [] as any
      }
    ];
    this.coLineChartEmissionData = [
      {
        "name": 'CO(ppm)',
        "series": [] as any
      }
    ];
    this.soxLineChartEmissionData = [
      {
        "name": 'SOx(ppm)',
        "series": [] as any
      }
    ];
    this.noxLineChartEmissionData = [
      {
        "name": 'NOx(ppm)',
        "series": [] as any
      }
    ];
    this.o2LineChartEmissionData = [
      {
        "name": 'O2(%V/V)',
        "series": [] as any
      }
    ];

    this.speedLineChartData = [
      {
        "name": "Speed",
        "series": [] as any
      }
    ];
  }

  stopPlayback() {
    // clearInterval(this.interval);
    this.alertList = [];
    this.resetGraphs();
    clearTimeout(this.pointInterval);
    clearTimeout(this.moveInterval);
    this.play.show = true;
    this.marker.setPosition(new google.maps.LatLng(this.markers[0].point[0], this.markers[0].point[1]));
    var icon = this.marker.getIcon();
    icon.rotation = 0;
    this.marker.setIcon(icon);
    this.target = 0;
  }
  startPlayback() {
    if (this.markers.length > 0) {
      this.play.show = false;
      this.animateMarker(this.marker, this.markers, 50);
    }

  }

  changeSpeed(val: any) {
    if (val) {
      if (this.playback_speed < 100000) {
        this.playback_speed += 10000;
      }
    }
    else {
      if (this.playback_speed > 10000) {
        this.playback_speed -= 10000;
      }
    }
  }

  pausePlayback() {
    this.play.show = true;
    if (this.moveInterval == null)
      clearTimeout(this.moveInterval);
    if (this.pointInterval == null)
      clearTimeout(this.pointInterval);
  }
  getVesselList() {
    this.historyService.getVesselList().subscribe(
      (res: any) => {
        if (res.statusCode == 200) {
          this.vesselList = res.data;
          this.vessel = this.vesselList[0].shipId;
          this.selectedDevice = this.vesselList[0];
          this.selectedDevice.selected = true;
          this.getVoyageList();
          this.getTripTimeline(0, 0, this.pageNumber);
        }
      }
    )
  }

  changeDevice() {
    this.getVoyageList();
    this.reloadHistory();
  }

  reloadHistory() {
    if (this.historyRange == 'recent') {
      this.getTripTimeline(0, 0, this.pageNumber);
    }
    if (this.historyRange == 'today') {
      let firstDate = new Date();
      this.setToMidNight(firstDate);
      // console.log(firstDate);
      this.date.fromDate = firstDate;
      this.date.toDate = new Date();
      this.getTripTimeline(this.date.fromDate.getTime(), this.date.toDate.getTime(), this.pageNumber);
    } else if (this.historyRange == 'last24') {
      let firstDate = new Date();
      firstDate.setHours(firstDate.getHours() - 24);
      this.date.fromDate = firstDate;
      this.date.toDate = new Date();
      this.getTripTimeline(this.date.fromDate.getTime(), this.date.toDate.getTime(), this.pageNumber);
    } else if (this.historyRange == 'last7') {
      let firstDate = new Date();
      firstDate.setDate(firstDate.getDate() - 7);
      this.setToMidNight(firstDate);
      this.date.fromDate = firstDate;
      this.date.toDate = new Date();
      this.getTripTimeline(this.date.fromDate.getTime(), this.date.toDate.getTime(), this.pageNumber)
    }
  }

  applyCustomDate() {
    let firstDate = this.range.value.fromDate;
    let lastDate = this.range.value.toDate;
    this.date.fromDate = firstDate;
    this.date.toDate = lastDate;
    this.getTripTimeline(this.date.fromDate.getTime(), this.date.toDate.getTime(), this.pageNumber)
  }
  setToMidNight(date: Date) {
    date.setHours(0);
    date.setMinutes(0);
    date.setSeconds(0);
  }
  goToDetails(device: any) {
    if (this.selectedDevice) {
      if (this.selectedDevice.shipId == device.shipId) {
        return;
      }
    }

    if (this.selectedDevice) {
      this.selectedDevice.selected = false;
    }
    this.selectedDevice = device;
    this.selectedDevice.selected = true;
  }
  labelText: any;

  animateMarker = (marker: any, coords: any, delay: any) => {
    var that = this;
    let goToPoint = function () {
      let lat = marker.position.lat();
      let lng = marker.position.lng();
      let step = (that.playback_speed * 1000 * delay) / 3600000; // in meters

      var dest = new google.maps.LatLng(
        coords[that.target].point[0], coords[that.target].point[1]);
      var distance =
        google.maps.geometry.spherical.computeDistanceBetween(
          dest, marker.position); // in meters

      let numStep = distance / step;
      let i = 0;
      let deltaLat = (coords[that.target].point[0] - lat) / numStep;
      let deltaLng = (coords[that.target].point[1] - lng) / numStep;

      if (that.target > 0) {
        that.speed = that.tripTimelineList[that.target].speed;
        that.location = that.tripTimelineList[that.target].lat + ',' + that.tripTimelineList[that.target].lng;
        that.time = new Date(that.tripTimelineList[that.target].ts);
        that.labelText = "Time:" + that.datePipe.transform(that.time * 1000, 'medium') +
          '<br/>' + "Speed:" + that.speed + ' Knots' + '<br/>' + "Location:" + that.location;
        marker.labelContent = that.labelText;
      }

      var icon = marker.getIcon();
      icon.rotation = coords[that.target].heading;
      marker.setIcon(icon);

      var moveMarker = function () {
        lat += deltaLat;
        lng += deltaLng;
        i += step;

        that.map.panTo(marker.getPosition());

        if (!that.play.show) {
          if (i < distance) {
            marker.setPosition(new google.maps.LatLng(lat, lng));
            let getBounds = that.map.getBounds()?.contains(marker.getPosition());
            if (!getBounds)
              that.map.setCenter(marker.getPosition());
            that.moveInterval = setTimeout(moveMarker, 1);
          }
          else {
            if (that.tripTimelineList[that.target].type == '4007') {
              let alarmMessage = "";
              if (that.tripTimelineList[that.target].attributes.alarmType == "NOX") {
                alarmMessage = `NOx value ${that.tripTimelineList[that.target].attributes.currentValue} ppm is above threshold of ${that.tripTimelineList[that.target].attributes.threshold} ppm. Detected at ${that.tripTimelineList[that.target].lat} , ${that.tripTimelineList[that.target].lng}  on  ${that.datePipe.transform(new Date(that.tripTimelineList[that.target].ts * 1000), 'medium')}`;
              }
              else if (that.tripTimelineList[that.target].attributes.alarmType == "CO2") {
                alarmMessage = `CO2 value ${that.tripTimelineList[that.target].attributes.currentValue} ppm is above threshold of ${that.tripTimelineList[that.target].attributes.threshold} ppm. Detected at ${that.tripTimelineList[that.target].lat} , ${that.tripTimelineList[that.target].lng}  on  ${that.datePipe.transform(new Date(that.tripTimelineList[that.target].ts * 1000), 'medium')}`;
              }
              else if (that.tripTimelineList[that.target].attributes.alarmType == "CO") {
                alarmMessage = `CO value ${that.tripTimelineList[that.target].attributes.currentValue} ppm is above threshold of ${that.tripTimelineList[that.target].attributes.threshold} ppm. Detected at ${that.tripTimelineList[that.target].lat} , ${that.tripTimelineList[that.target].lng}  on  ${that.datePipe.transform(new Date(that.tripTimelineList[that.target].ts * 1000), 'medium')}`;
              }
              else if (that.tripTimelineList[that.target].attributes.alarmType == "SOX") {
                alarmMessage = `SOx value ${that.tripTimelineList[that.target].attributes.currentValue} ppm is above threshold of ${that.tripTimelineList[that.target].attributes.threshold} ppm. Detected at ${that.tripTimelineList[that.target].lat} , ${that.tripTimelineList[that.target].lng}  on  ${that.datePipe.transform(new Date(that.tripTimelineList[that.target].ts * 1000), 'medium')}`;
              }
              else if (that.tripTimelineList[that.target].attributes.alarmType == "O2") {
                alarmMessage = `O2 value ${that.tripTimelineList[that.target].attributes.currentValue} ppm is above threshold of ${that.tripTimelineList[that.target].attributes.threshold} ppm. Detected at ${that.tripTimelineList[that.target].lat} , ${that.tripTimelineList[that.target].lng}  on  ${that.datePipe.transform(new Date(that.tripTimelineList[that.target].ts * 1000), 'medium')}`;
              }
              that.alertList.unshift({ message: alarmMessage });
            }
            if (that.co2LineChartEmissionData[0].series.length > 50) {
              that.co2LineChartEmissionData[0].series.shift()
            }
            if (that.coLineChartEmissionData[0].series.length > 50) {
              that.coLineChartEmissionData[0].series.shift()
            }
            if (that.noxLineChartEmissionData[0].series.length > 50) {
              that.noxLineChartEmissionData[0].series.shift()
            }
            if (that.soxLineChartEmissionData[0].series.length > 50) {
              that.soxLineChartEmissionData[0].series.shift()
            }
            if (that.o2LineChartEmissionData[0].series.length > 50) {
              that.o2LineChartEmissionData[0].series.shift()
            }
            if (that.speedLineChartData[0].series.length > 50) {
              that.speedLineChartData[0].series.shift()
            }

            that.co2LineChartEmissionData[0].series.push({ "name": new Date, "value": that.tripTimelineList[that.target].crbnDiOx });
            that.coLineChartEmissionData[0].series.push({ "name": new Date, "value": that.tripTimelineList[that.target].crbnMoOx });
            that.noxLineChartEmissionData[0].series.push({ "name": new Date, "value": that.tripTimelineList[that.target].nitrOxi });
            that.soxLineChartEmissionData[0].series.push({ "name": new Date, "value": that.tripTimelineList[that.target].sox });
            that.o2LineChartEmissionData[0].series.push({ "name": new Date, "value": that.tripTimelineList[that.target].oxi });
            that.speedLineChartData[0].series.push({ "name": new Date, "value": that.tripTimelineList[that.target].speed });

            that.co2Chart.data = [['CO2', that.tripTimelineList[that.target].crbnDiOx]];
            that.coChart.data = [['CO', that.tripTimelineList[that.target].crbnMoOx]];
            that.noxChart.data = [['NOx', that.tripTimelineList[that.target].nitrOxi]];
            that.soxChart.data = [['SOx', that.tripTimelineList[that.target].sox]];
            that.o2Chart.data = [['O2', that.tripTimelineList[that.target].oxi]];
            that.speedChart.data = [['Speed', that.tripTimelineList[that.target].speed]];

            that.co2LineChartEmissionData = Object.assign([that.tripTimelineList[that.target].crbnDiOx], that.co2LineChartEmissionData);
            that.coLineChartEmissionData = Object.assign([that.tripTimelineList[that.target].crbnMoOx], that.coLineChartEmissionData);
            that.noxLineChartEmissionData = Object.assign([that.tripTimelineList[that.target].nitrOxi], that.noxLineChartEmissionData);
            that.soxLineChartEmissionData = Object.assign([that.tripTimelineList[that.target].sox], that.soxLineChartEmissionData);
            that.o2LineChartEmissionData = Object.assign([that.tripTimelineList[that.target].oxi], that.o2LineChartEmissionData);
            that.speedLineChartData = Object.assign([that.tripTimelineList[that.target].speed], that.speedLineChartData);

            marker.setPosition(dest);

            that.target++;
            if (that.target == coords.length) {
              that.stopPlayback();
            }
            that.pointInterval = setTimeout(goToPoint, 1);
          }
        }

      }
      moveMarker();
    }
    goToPoint();
  }

  //chart options strt
  co2LineChartEmissionData = [
    {
      "name": 'CO2(%V/V)',
      "series": [] as any
    }
  ];
  coLineChartEmissionData = [
    {
      "name": 'CO(ppm)',
      "series": [] as any
    }
  ];
  soxLineChartEmissionData = [
    {
      "name": 'SOx(ppm)',
      "series": [] as any
    }
  ];
  noxLineChartEmissionData = [
    {
      "name": 'NOx(ppm)',
      "series": [] as any
    }
  ];
  o2LineChartEmissionData = [
    {
      "name": 'O2(%V/V)',
      "series": [] as any
    }
  ];

  speedLineChartData = [
    {
      "name": "Speed",
      "series": [] as any
    }
  ];

  viewLineChart: any[] = [300, 180];

  co2Chart = {
    type: 'Gauge',
    data: [
      ['CO2', 0]
    ],
    options: {
      width: 250,
      height: 180,
      greenFrom: 0,
      greenTo: 8,
      redFrom: 16,
      redTo: 25,
      yellowFrom: 8,
      yellowTo: 16,
      minorTicks: 5,
      max: 25,
      majorTicks: [0, 5, 10, 15, 20, 25]
    }
  };
  coChart = {
    type: 'Gauge',
    data: [
      ['CO', 0]
    ],
    options: {
      width: 280,
      height: 180,
      greenFrom: 0,
      greenTo: 667,
      redFrom: 1333,
      redTo: 2000,
      yellowFrom: 667,
      yellowTo: 1333,
      minorTicks: 5,
      max: 2000,
      majorTicks: [0, 400, 800, 1200, 1600, 2000]
    }
  };
  noxChart = {
    type: 'Gauge',
    data: [
      ['NOx', 0]
    ],
    options: {
      width: 280,
      height: 180,
      greenFrom: 0,
      greenTo: 667,
      redFrom: 1333,
      redTo: 2000,
      yellowFrom: 667,
      yellowTo: 1333,
      minorTicks: 5,
      max: 2000,
      majorTicks: [0, 400, 800, 1200, 1600, 2000]
    }
  };
  soxChart = {
    type: 'Gauge',
    data: [
      ['SOx', 0]
    ],
    options: {
      width: 280,
      height: 180,
      greenFrom: 0,
      greenTo: 667,
      redFrom: 1333,
      redTo: 2000,
      yellowFrom: 667,
      yellowTo: 1333,
      minorTicks: 5,
      max: 2000,
      majorTicks: [0, 400, 800, 1200, 1600, 2000]
    }
  };
  o2Chart = {
    type: 'Gauge',
    data: [
      ['O2', 0]
    ],
    options: {
      width: 280,
      height: 180,
      greenFrom: 0,
      greenTo: 8,
      redFrom: 16,
      redTo: 25,
      yellowFrom: 8,
      yellowTo: 16,
      minorTicks: 5,
      max: 25,
      majorTicks: [0, 5, 10, 15, 20, 25]
    }
  };
  speedChart = {
    type: 'Gauge',
    data: [
      ['Speed', 0]
    ],
    options: {
      width: 280,
      height: 180,
      greenFrom: 0,
      greenTo: 15,
      redFrom: 24,
      redTo: 40,
      yellowFrom: 15,
      yellowTo: 24,
      minorTicks: 5,
      max: 40,
      majorTicks: [0, 8, 16, 24, 32, 40]
    }
  };

  showXAxis = false;
  showYAxis = true;
  gradient = false;
  showLegend = false;
  showXAxisLabel = false;
  xAxisLabel = 'Time';
  showYAxisLabel = true;
  yAxisCO2Label = 'CO2(%V/V)';
  yAxisNOxLabel = "NOx(ppm)";
  yAxisSOxLabel = "SOx(ppm)";
  yAxisCOLabel = 'CO(ppm)';
  yAxisO2Label = "O2(%V/V)";
  yAxisSpeedLabel = 'Speed';
  co2ReferenceLine = [{ name: 'Maximum', value: 16 }];
  coReferenceLine = [{ name: 'Maximum', value: 1400 }]
  noxReferenceLine = [{ name: 'Maximum', value: 1400 }]
  soxReferenceLine = [{ name: 'Maximum', value: 1400 }]
  o2ReferenceLine = [{ name: 'Maximum', value: 16 }]
  co2ColorScheme = {
    domain: ['#5AA454']
  };
  coColorScheme = {
    domain: ['#A10A28']
  }
  noxColorScheme = {
    domain: ['#C7B42C']
  }
  soxColorScheme = {
    domain: ['#AAAAAA']
  }
  o2ColorScheme = {
    domain: ['#FFA500']
  }
  speedColorScheme = {
    domain: ['#000080']
  }
  autoScale = false;
  //chart ends
}
