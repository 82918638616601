import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AdminDetailService } from 'src/app/shared/admin-detail.service';
import { UpdateAssignmentService } from './update-assignment.service';

@Component({
  selector: 'app-update-assignment',
  templateUrl: './update-assignment.component.html',
  styleUrls: ['./update-assignment.component.css']
})
export class UpdateAssignmentComponent implements OnInit {

  constructor(private fb: FormBuilder,
    public dialogRef: MatDialogRef<UpdateAssignmentComponent>,
    private adminDetailService: AdminDetailService,
    private updateAssignmentService: UpdateAssignmentService,
    private datePipe: DatePipe,
    @Inject(MAT_DIALOG_DATA) public data: any,) { }

  charterTypeList: any = ['Period', 'Time', 'Voyage'];
  updateAssignmentForm!: FormGroup;
  vesselList: any = [];

  ngOnInit(): void {
    this.updateAssignmentForm = this.fb.group({
      charterer: [this.data.character, Validators.required],
      charterType: [this.data.characterType],
      dateOfDelivery: [new Date(this.data.dateOfDelivery)],
      dateOfDeparture: [new Date(this.data.dateOfDeparture)],
      placeOfDelivery: [this.data.placeOfDelivery],
      termOfDelivery: [this.data.termOfDelivery],
      ifoFuel: [this.data.fuelTypeIfoInit],
      mdoFuel: [this.data.fuelTypeMdoInit],
      ifoBoard: [this.data.bunkerOnBoardIfoInit, Validators.min(0)],
      mdoBoard: [this.data.bunkerOnBoardMdoInit, Validators.min(0)],
      loadport: [this.data.loadPort],
      // bunkeringAt: [null],
      // bunkerProvider: [null],
      // ifoLoaded: [null],
      // mdoLoaded: [null],
      dischargePort: [this.data.dischargePort],

    })
  }


  updateAssignment() {
    var formData: any = {};
    formData.bunkerOnBoardIfoInit = this.updateAssignmentForm.value.ifoBoard;
    formData.bunkerOnBoardMdoInit = this.updateAssignmentForm.value.mdoBoard;
    // formData.bunkerProvider = this.updateAssignmentForm.value.bunkerProvider;
    // formData.bunkeringAt = this.updateAssignmentForm.value.bunkeringAt;
    formData.character = this.updateAssignmentForm.value.charterer;
    formData.characterType = this.updateAssignmentForm.value.charterType;
    formData.loadPort = this.updateAssignmentForm.value.loadport;
    formData.placeOfDelivery = this.updateAssignmentForm.value.placeOfDelivery;
    formData.dischargePort = this.updateAssignmentForm.value.dischargePort;
    formData.fuelTypeIfoInit = this.updateAssignmentForm.value.ifoFuel;
    formData.fuelTypeMdoInit = this.updateAssignmentForm.value.mdoFuel;
    formData.termOfDelivery = this.updateAssignmentForm.value.termOfDelivery;
    formData.dateOfDeliveryStr = this.datePipe.transform(this.updateAssignmentForm.value.dateOfDelivery, 'yyyy-MM-dd HH:mm:ss');
    // formData.userId = this.adminDetailService.userId;
    formData.voyageId = this.data.id;
    formData.dateOfDepartureStr = this.datePipe.transform(this.updateAssignmentForm.value.dateOfDeparture, 'yyyy-MM-dd HH:mm:ss');
    console.log(formData);
    this.updateAssignmentService.updateAssignment(formData).subscribe(
      (res) => {
        if (res) {
          console.log(res);
          this.dialogRef.close();
        }

      }, (err) => {
        alert("Voyage don't added successfully: " + err.error.message);
      }
    )
  }

  closeModal() {
    this.dialogRef.close();
  }

}
