import { HistoryService } from './history.service';
import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { GoogleChartsModule } from 'angular-google-charts';
import { HistoryComponent } from './history.component';
import { HistoryRoutingModule } from './history-routing.module';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatButtonModule } from '@angular/material/button';
import { NgxMatDatetimePickerModule, NgxMatNativeDateModule, NgxMatTimepickerModule } from '@angular-material-components/datetime-picker';
import { MatInputModule } from '@angular/material/input';

@NgModule({
    declarations: [
        HistoryComponent
    ],
    imports: [
        ReactiveFormsModule,
        HttpClientModule,
        RouterModule,
        CommonModule,
        MatCardModule,
        MatIconModule,
        NgxChartsModule,
        GoogleChartsModule,
        HistoryRoutingModule,
        MatFormFieldModule,
        MatSelectModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatButtonModule,
        NgxMatDatetimePickerModule,
        NgxMatTimepickerModule,
        NgxMatNativeDateModule,
        MatInputModule,

    ],
    providers: [HistoryService, MatDatepickerModule],
    bootstrap: []
})
export class HistoryModule { }