import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MatSidenav } from '@angular/material/sidenav';
import { AdminDetailService } from 'src/app/shared/admin-detail.service';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.css']
})
export class NavigationComponent implements OnInit {

  constructor(public router: Router,
    public adminDetailService: AdminDetailService,) {

  }
  @ViewChild('drawer', { static: false })
  drawer!: MatSidenav;

  ngOnInit(): void {
  }

  logout() {
    sessionStorage.clear();
    this.router.navigateByUrl('login');

  }
}
