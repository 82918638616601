import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-noon-report-details',
  templateUrl: './noon-report-details.component.html',
  styleUrls: ['./noon-report-details.component.css']
})
export class NoonReportDetailsComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, 
    public dialogRef: MatDialogRef<NoonReportDetailsComponent>,) { }

  ngOnInit(): void {
  }

}
